export enum UserRole {
  DEVELOPER = "DEVELOPER",
  SUPER_ADMIN = "SUPER_ADMIN",
  EMPLOYEE = "EMPLOYEE",
}

export enum DealerRole {
  ADMIN = "ADMIN",
  MECHANICAL_PREP = "MECHANICAL_PREP",
  BODY_DAMAGE = "BODY_DAMAGE",
  SALES = "SALES",
  SALES_MANAGER = "SALES_MANAGER",
  SALES_SUPPORT = "SALES_SUPPORT",
  SALES_EXECUTIVE = "SALES_EXECUTIVE",
}
