import RtkQueryService from "./RtkQueryService";

interface User {
  id: string;
  name: string;
  username: string;
  email: string;
  createdAt: string;
  roles: {
    id: string;
    code: string;
    name: string;
  }[];
  dealers: {
    id: string;
    name: string;
    roles: string[];
  }[];
  profilePhotoUrl: string | null;
}

interface GetUsersRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
}

export interface GetUsersResponse {
  data: {
    data: User[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindUserResponse {
  data: {
    id: string;
    name: string;
    username: string;
    email: string;
    createdAt: string;
    profilePhotoUrl: string | null;
    profilePhoto: {
      id: string;
      name: string;
      filename: string;
      size: number;
      mimeType: string;
    } | null;
  };
}

interface StoreUserRequest {
  name: string;
  username: string;
  email: string;
  password: string;
  profilePhotoFileId: string | null;
}

interface StoreUserResponse {
  message: string;
}

interface UpdateUserRequest {
  id: string;
  data: {
    name: string;
    username: string;
    email: string;
    profilePhotoFileId?: string | null; // string = add/replace, null = remove, undefined = no changes
  };
}

interface UpdateUserPasswordRequest {
  id: string;
  data: {
    password: string;
  };
}

interface UpdateUserResponse {
  message: string;
}

interface DeleteUserResponse {
  message: string;
}

export const RTK_USERS_TAG = "Users" as const;

const apiUsers = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_USERS_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<GetUsersResponse, GetUsersRequest>({
      query: (params) => ({
        url: `/users`,
        method: "GET",
        params: params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_USERS_TAG,
                id,
              })),
              { type: RTK_USERS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_USERS_TAG, id: "LIST" }],
    }),
    findUser: build.query<FindUserResponse, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
      providesTags: (result, _, id) =>
        result ? [{ type: RTK_USERS_TAG, id }] : [],
    }),
    storeUser: build.mutation<StoreUserResponse, StoreUserRequest>({
      query: (data) => ({
        url: `/users`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RTK_USERS_TAG, id: "LIST" }] : [],
    }),
    updateUser: build.mutation<UpdateUserResponse, UpdateUserRequest>({
      query: ({ id, data }) => ({
        url: `/users/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: RTK_USERS_TAG, id }] : [],
    }),
    updateUsersPassword: build.mutation<
      UpdateUserResponse,
      UpdateUserPasswordRequest
    >({
      query: ({ id, data }) => ({
        url: `/users/${id}/password`,
        method: "PATCH",
        data: data,
      }),
    }),
    deleteUser: build.mutation<DeleteUserResponse, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, id) =>
        result ? [{ type: RTK_USERS_TAG, id }] : [],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useFindUserQuery,
  useStoreUserMutation,
  useUpdateUserMutation,
  useUpdateUsersPasswordMutation,
  useDeleteUserMutation,
} = apiUsers;

export default apiUsers;
