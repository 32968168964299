import { Loader2Icon } from "lucide-react";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Helmet from "../Helmet";

const AuthLayout = () => {
  return (
    <>
      <Helmet />
      <div className="h-screen">
        <Suspense
          fallback={
            <div className="flex h-full items-center justify-center">
              <Loader2Icon className="h-8 w-8 animate-spin" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </>
  );
};

export default AuthLayout;
