import { RTK_VEHICLE_TAG } from "../VehicleService";
import RtkQueryService from "../RtkQueryService";
import { RTK_STAGE_ADMIN_TAG } from "./adminService";
import { RTK_STAGE_BODY_DAMAGE_TAG } from "./bodyDamageService";
import { RTK_STAGE_MECHANICAL_PREP_TAG } from "./mechanicalPrepService";
import { RTK_STAGE_MEDIA_TAG } from "./mediaService";
import { RTK_STAGE_SALE_TAG } from "./saleService";
import { RTK_REPORT_TAG } from "../ReportService";

interface MessageResponse {
  message: string;
}

interface FindStageOffsiteVehicleResponse {
  data: {
    etaEcd: string | null;
    location: string | null;
    etaEcdHistory: {
      id: string;
      prevEtaEcd: string | null;
      newEtaEcd: string;
      note: string;
      createdAt: string;
      user: {
        id: string;
        name: string;
      };
    }[];
    note: string | null;
  };
}

interface InitiateStageOffsiteRequest {
  etaEcd: string;
  etaEcdNote: string;
  location: string;
  note: string;
}

interface UpdateStageOffsiteRequest extends InitiateStageOffsiteRequest {}

interface OnsiteStageOffsiteRequest extends InitiateStageOffsiteRequest {}

export const RTK_STAGE_OFFSITE_TAG = "StageOffsite" as const;

const apiStageOffsite = RtkQueryService.enhanceEndpoints({
  addTagTypes: [
    RTK_VEHICLE_TAG,
    RTK_STAGE_ADMIN_TAG,
    RTK_STAGE_MECHANICAL_PREP_TAG,
    RTK_STAGE_BODY_DAMAGE_TAG,
    RTK_STAGE_MEDIA_TAG,
    RTK_STAGE_SALE_TAG,
    RTK_STAGE_OFFSITE_TAG,
    //
    RTK_REPORT_TAG,
  ],
}).injectEndpoints({
  endpoints: (build) => ({
    findStageOffsiteVehicle: build.query<
      FindStageOffsiteVehicleResponse,
      string
    >({
      query: (vehicleId) => ({
        url: `/stage/${vehicleId}/offsite`,
        method: "GET",
      }),
      providesTags: (_, __, vehicleId) => [
        { type: RTK_STAGE_OFFSITE_TAG, id: vehicleId },
      ],
    }),
    // ---
    initiateStageOffsite: build.mutation<
      MessageResponse,
      { vehicleId: string; data: InitiateStageOffsiteRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/${vehicleId}/offsite`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_ADMIN_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
              { type: RTK_STAGE_OFFSITE_TAG, id: vehicleId },
              //
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    updateStageOffsite: build.mutation<
      MessageResponse,
      { vehicleId: string; data: UpdateStageOffsiteRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/${vehicleId}/offsite`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_OFFSITE_TAG, id: vehicleId },
            ]
          : [],
    }),
    onsiteStageOffsite: build.mutation<
      MessageResponse,
      { vehicleId: string; data: OnsiteStageOffsiteRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/${vehicleId}/onsite`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_ADMIN_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
              { type: RTK_STAGE_OFFSITE_TAG, id: vehicleId },
              //
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
  }),
});

export const {
  useFindStageOffsiteVehicleQuery,
  //
  useInitiateStageOffsiteMutation,
  useUpdateStageOffsiteMutation,
  useOnsiteStageOffsiteMutation,
} = apiStageOffsite;

export default apiStageOffsite;
