import { UserRole } from "@/types/role";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/Dialog";
import { RadioGroup, RadioGroupItem } from "../ui/RadioGroup";
import { Label } from "../ui/Label";
import {
  AlertCircleIcon,
  Loader2Icon,
  User2Icon,
  Users2Icon,
} from "lucide-react";
import { Button } from "../ui/Button";
import { useEffect, useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "../ui/Alert";
import { cn } from "@/lib/utils";

interface SwitchRoleDialogProps {
  open: boolean;
  activeRole: UserRole;
  loading: boolean;
  onSubmit: (role: UserRole) => void;
  onClose: () => void;
}

const SwitchRoleDialog = ({
  open,
  activeRole,
  loading,
  onSubmit,
  onClose,
}: SwitchRoleDialogProps) => {
  const [error, setError] = useState("");
  const [value, setValue] = useState<UserRole>();

  const onValueChange = (role: UserRole) => {
    setError("");
    setValue(role);
  };

  const handleSubmit = () => {
    setError("");

    if (!value) {
      return setError("Please select a role");
    }

    onSubmit(value);
  };

  useEffect(() => {
    setError("");
    setValue(activeRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-sm">
        <DialogHeader>
          <DialogTitle>Switch Role</DialogTitle>
          <DialogDescription>Choose a role to switch to</DialogDescription>
        </DialogHeader>
        {error && (
          <Alert variant="destructive">
            <AlertCircleIcon className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div>
          <RadioGroup
            value={value}
            onValueChange={onValueChange}
            className="grid grid-cols-2 gap-4"
          >
            <div>
              <RadioGroupItem
                value={UserRole.SUPER_ADMIN}
                id={`role-${UserRole.SUPER_ADMIN}`}
                className="peer sr-only"
                checked={value === UserRole.SUPER_ADMIN}
              />
              <Label
                htmlFor={`role-${UserRole.SUPER_ADMIN}`}
                className={cn(
                  "flex flex-col items-center justify-between gap-2 rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                  activeRole === UserRole.SUPER_ADMIN &&
                    "bg-primary/10 hover:bg-primary/10",
                )}
              >
                <User2Icon className="h-5 w-5" />
                Super Admin
              </Label>
            </div>
            <div>
              <RadioGroupItem
                value={UserRole.EMPLOYEE}
                id={`role-${UserRole.EMPLOYEE}`}
                className="peer sr-only"
                checked={value === UserRole.EMPLOYEE}
              />
              <Label
                htmlFor={`role-${UserRole.EMPLOYEE}`}
                className={cn(
                  "flex flex-col items-center justify-between gap-2 rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                  activeRole === UserRole.EMPLOYEE &&
                    "bg-primary/10 hover:bg-primary/10",
                )}
              >
                <Users2Icon className="h-5 w-5" />
                Employee
              </Label>
            </div>
          </RadioGroup>

          <div className="mt-3 text-sm text-muted-foreground">
            Your current role is{" "}
            <strong className="capitalize">{activeRole.toLowerCase()}</strong>
          </div>
        </div>
        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={loading || activeRole === value}
          >
            {loading && <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />}
            Switch
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SwitchRoleDialog;
