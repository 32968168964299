/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_ERROR_MESSAGE } from "@/constants/app.constant";
import { AxiosError, isAxiosError } from "axios";
import { UseFormSetError } from "react-hook-form";

interface ValidationError {
  code: string;
  path: string[];
  message: string;
}

interface ValidationErrorResponse {
  message: string;
  errors: ValidationError[];
}

export function isAxiosValidationError(
  error: unknown,
): error is AxiosError<ValidationErrorResponse> {
  return (
    isAxiosError(error) &&
    typeof (error.response?.data as any)?.message === "string" &&
    Array.isArray((error.response?.data as any)?.errors) &&
    (error.response?.data as any)?.errors.every(
      (err: any) =>
        typeof err === "object" &&
        "code" in err &&
        typeof err.code === "string" &&
        "path" in err &&
        Array.isArray(err.path) &&
        err.path.every((p: any) => typeof p === "string") &&
        "message" in err &&
        typeof err.message === "string",
    )
  );
}

export function isAxiosNetworkError(error: unknown): error is AxiosError {
  return isAxiosError(error) && error.message === "Network Error";
}

export function isAxiosNotFoundError(error: unknown): boolean {
  return isAxiosError(error) && error.response?.status === 404;
}

export function isAxiosRateLimitError(error: unknown): boolean {
  return isAxiosError(error) && error.response?.status === 429;
}

function isAxiosErrorWithMessage(
  error: unknown,
): error is AxiosError<{ message: string }> {
  return (
    isAxiosError(error) && typeof error.response?.data?.message === "string"
  );
}

export function handleAxiosError(
  error: unknown,
  handleError: (errorMessage: string) => void,
  defaultErrorMessage = DEFAULT_ERROR_MESSAGE,
) {
  if (isAxiosErrorWithMessage(error)) {
    handleError(error.response!.data.message);
  } else if (isAxiosNetworkError(error)) {
    handleError("Network error. Please check your connection.");
  } else if (isAxiosRateLimitError(error)) {
    handleError("Rate limit exceeded. Please try again later.");
  } else {
    console.error(error);
    handleError(defaultErrorMessage);
  }
}

export function handleAxiosValidationErrors(
  error: unknown,
  setError: UseFormSetError<any>,
) {
  if (isAxiosValidationError(error)) {
    error.response!.data.errors.forEach((err) => {
      setError(err.path[0] as string, {
        message: err.message,
      });
    });
  } else {
    handleAxiosError(error, (message) => setError("root", { message }));
  }
}
