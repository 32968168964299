import { Link } from "react-router-dom";
import { buttonVariants } from "../ui/Button";

const PageNotFoundInfo = () => {
  return (
    <div className="container flex h-full flex-col items-center justify-center">
      <h1 className="text-5xl font-bold text-foreground">404</h1>
      <div className="mt-6 text-muted-foreground">
        We are sorry but the page you are looking for does not exist.
      </div>
      <Link to="/" className={buttonVariants({ className: "mt-6" })}>
        Back to home
      </Link>
    </div>
  );
};

export default PageNotFoundInfo;
