import { DealerRole, UserRole } from "@/types/role";
import RtkQueryService from "./RtkQueryService";

interface LoginRequest {
  email: string;
  password: string;
}

interface LoginResponse {
  data: {
    user: {
      id: string;
      name: string;
      username: string;
      email: string;
      availableDealers: {
        id: string;
        code: string;
        name: string;
        groupLogoLightUrl: string | null;
        groupLogoDarkUrl: string | null;
        mainRoles: UserRole[];
      }[];
    };
    session: {
      activeDealerId: string | null;
      activeRole: UserRole;
      activeDealerRoles: DealerRole[];
      accessToken: string;
      refreshToken: string;
    };
  };
}

interface LogoutResponse {
  message: string;
}

interface CheckResponse {
  message: string;
}

export const RTK_AUTH_TAG = "Auth" as const;

const apiAuth = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_AUTH_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        data: data,
      }),
      invalidatesTags: [RTK_AUTH_TAG],
    }),
    logout: build.mutation<LogoutResponse, void>({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      invalidatesTags: [RTK_AUTH_TAG],
    }),
    check: build.query<CheckResponse, void>({
      query: () => ({
        url: "/auth/check",
        method: "GET",
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useLazyCheckQuery } =
  apiAuth;

export default apiAuth;
