import RtkQueryService from "./RtkQueryService";
import {
  DetailVehicleResponse,
  FindVehicleResponse,
  GetAgencyStockVehiclesRequest,
  GetAgencyStockVehiclesResponse,
  GetVehiclesRequest,
  GetVehiclesResponse,
} from "./VehicleService.types";

export const RTK_VEHICLE_TAG = "Vehicle" as const;
export const RTK_VEHICLE_AGENCY_STOCK_TAG = "VehicleAgencyStock" as const;

const apiVehicle = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_VEHICLE_TAG, RTK_VEHICLE_AGENCY_STOCK_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getVehicles: build.query<GetVehiclesResponse, GetVehiclesRequest>({
      query: (params) => ({
        url: `/vehicles`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_VEHICLE_TAG,
                id: id,
              })),
              { type: RTK_VEHICLE_TAG, id: "LIST" },
            ]
          : [{ type: RTK_VEHICLE_TAG, id: "LIST" }],
    }),
    findVehicle: build.query<FindVehicleResponse, string>({
      query: (id) => ({
        url: `/vehicles/${id}`,
        method: "GET",
      }),
      providesTags: (result, _, id) =>
        result ? [{ type: RTK_VEHICLE_TAG, id }] : [],
    }),
    detailsVehicle: build.query<DetailVehicleResponse, string>({
      query: (id) => ({
        url: `/vehicles/${id}/details`,
        method: "GET",
      }),
      providesTags: (result, _, id) =>
        result ? [{ type: RTK_VEHICLE_TAG, id }] : [],
    }),
    // ---
    getAgencyStockVehicles: build.query<
      GetAgencyStockVehiclesResponse,
      GetAgencyStockVehiclesRequest
    >({
      query: (params) => ({
        url: `/vehicles/agency-stock`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_VEHICLE_AGENCY_STOCK_TAG,
                id: id,
              })),
              { type: RTK_VEHICLE_AGENCY_STOCK_TAG, id: "LIST" },
            ]
          : [{ type: RTK_VEHICLE_AGENCY_STOCK_TAG, id: "LIST" }],
    }),
  }),
});

export const {
  useGetVehiclesQuery,
  useFindVehicleQuery,
  useDetailsVehicleQuery,
  //
  useGetAgencyStockVehiclesQuery,
} = apiVehicle;

export default apiVehicle;
