interface AppConfig {
  apiPrefix: string;
  authenticatedEntryPath: string;
  unAuthenticatedEntryPath: string;
}

const appConfig: AppConfig = {
  apiPrefix: import.meta.env.VITE_API_URL,
  authenticatedEntryPath: "/",
  unAuthenticatedEntryPath: "/login",
};

export default appConfig;
