import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE_BASE_NAME } from "./constants";
import { DealerRole, UserRole } from "@/types/role";

export interface SessionState {
  authenticated: boolean;
  activeDealerId: string | null;
  activeDealerRoles: DealerRole[];
  activeRole: UserRole | null;
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: SessionState = {
  authenticated: false,
  activeDealerId: null,
  activeDealerRoles: [],
  activeRole: null,
  accessToken: null,
  refreshToken: null,
};

const sessionSlice = createSlice({
  name: `${SLICE_BASE_NAME}/session`,
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    },
    setRefreshToken(state, action: PayloadAction<string | null>) {
      state.refreshToken = action.payload;
    },
    updateSession(
      state,
      action: PayloadAction<{
        activeDealerId: string | null;
        activeDealerRoles: DealerRole[];
        activeRole: UserRole;
        accessToken: string;
        refreshToken: string;
      }>,
    ) {
      state.activeDealerId = action.payload.activeDealerId;
      state.activeDealerRoles = action.payload.activeDealerRoles;
      state.activeRole = action.payload.activeRole;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    signInSuccess(
      state,
      action: PayloadAction<{
        activeDealerId: string | null;
        activeDealerRoles: DealerRole[];
        activeRole: UserRole;
        accessToken: string;
        refreshToken: string;
      }>,
    ) {
      state.authenticated = true;
      state.activeDealerId = action.payload.activeDealerId;
      state.activeDealerRoles = action.payload.activeDealerRoles;
      state.activeRole = action.payload.activeRole;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    signOutSuccess(state) {
      state.authenticated = false;
      state.activeDealerId = null;
      state.activeDealerRoles = [];
      state.activeRole = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  updateSession,
  signInSuccess,
  signOutSuccess,
} = sessionSlice.actions;
export default sessionSlice.reducer;
