import { Link } from "react-router-dom";
import { buttonVariants } from "../ui/Button";
import { AlertTriangleIcon } from "lucide-react";

const ForbiddenInfo = () => {
  return (
    <div className="container flex h-full flex-col items-center justify-center">
      <AlertTriangleIcon size={100} className="text-slate-300" />
      <h1 className="mt-10 text-5xl font-bold text-foreground">Forbidden</h1>
      <div className="mt-6 text-muted-foreground">
        You don't have permission to access this page.
      </div>
      <Link to="/" className={buttonVariants({ className: "mt-6" })}>
        Back to home
      </Link>
    </div>
  );
};

export default ForbiddenInfo;
