export const workflowStageStatus = {
  INFLEET: {
    _: "INFLEET",
    ENTRY_DATA: "ENTRY_DATA",
  },
  ADMIN: {
    _: "ADMIN",
    CONFIGURE: "CONFIGURE",
  },
  ICT: {
    _: "ICT",
    ICT: "ICT",
  },
  MECHANICAL_PREP: {
    _: "MECHANICAL_PREP",
    ASSESS: "ASSESS",
    QUOTE: "QUOTE",
    QUOTE_REVISION: "QUOTE_REVISION",
    APPROVAL: "APPROVAL",
    IN_WORKSHOP: "IN_WORKSHOP",
  },
  BODY_DAMAGE: {
    _: "BODY_DAMAGE",
    ASSESS: "ASSESS",
    QUOTE: "QUOTE",
    QUOTE_REVISION: "QUOTE_REVISION",
    APPROVAL: "APPROVAL",
    REPAIR: "REPAIR",
  },
  MEDIA: {
    _: "MEDIA",
    VALET: "VALET",
    MEDIA: "MEDIA",
  },
  SALE: {
    _: "SALE",
    POST: "POST",
    ON_SALE: "ON_SALE",
  },
  OFFSITE: {
    _: "OFFSITE",
    OFFSITE: "OFFSITE",
  },
  CLOSED: {
    _: "CLOSED",
    SOLD_DIRECTLY: "SOLD_DIRECTLY",
    SOLD: "SOLD",
  },
} as const;
