import RtkQueryService from "./RtkQueryService";

interface SaleType {
  id: string;
  code: string;
  name: string;
}

export interface GetSaleTypeOptionsResponse {
  data: SaleType[];
}

export const RTK_SALE_TYPES_TAG = "SaleTypes" as const;
export const RTK_SALE_TYPE_OPTIONS_TAG = "SaleTypeOptions" as const;

const apiSaleType = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_SALE_TYPES_TAG, RTK_SALE_TYPE_OPTIONS_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getSaleTypeOptions: build.query<GetSaleTypeOptionsResponse, void>({
      query: () => ({
        url: `/options/sale-types`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_SALE_TYPE_OPTIONS_TAG,
                id,
              })),
              { type: RTK_SALE_TYPE_OPTIONS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_SALE_TYPE_OPTIONS_TAG, id: "LIST" }],
    }),
  }),
});

export const { useGetSaleTypeOptionsQuery } = apiSaleType;

export default apiSaleType;
