import RtkQueryService from "./RtkQueryService";

interface FileUploadConfig {
  id: string;
  code: string;
  name: string;
  maxSize: number;
  extensions: string;
  sensitive: boolean;
  createdAt: string;
}

interface FindFileUploadConfigByCode {
  data: {
    id: string;
    code: string;
    maxSize: number;
    extensions: string;
  };
}

interface GetFileUploadConfigsRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
}

export interface GetFileUploadConfigsResponse {
  data: {
    data: FileUploadConfig[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindFileUploadConfigResponse {
  data: FileUploadConfig;
}

interface StoreFileUploadConfigRequest {
  code: string;
  name: string;
  maxSize: number;
  extensions: string;
  sensitive: boolean;
}

interface StoreFileUploadConfigResponse {
  message: string;
}

interface UpdateFileUploadConfigRequest {
  id: string;
  data: {
    code: string;
    name: string;
    maxSize: number;
    extensions: string;
    sensitive: boolean;
  };
}

interface UpdateFileUploadConfigResponse {
  message: string;
}

export const RTK_FILE_UPLOAD_CONFIGS_TAG = "FileUploadConfigs" as const;
export const RTK_FILE_UPLOAD_CONFIGS_CODE_TAG =
  "FileUploadConfigsCode" as const;

const apiFileUploadsConfig = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_FILE_UPLOAD_CONFIGS_TAG, RTK_FILE_UPLOAD_CONFIGS_CODE_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    findFileUploadConfigByCode: build.query<FindFileUploadConfigByCode, string>(
      {
        query: (id) => ({
          url: `/file-upload-configs/code/${id}`,
          method: "GET",
        }),
        providesTags: (result, _, id) =>
          result ? [{ type: RTK_FILE_UPLOAD_CONFIGS_CODE_TAG, id }] : [],
      },
    ),
    getFileUploadConfigs: build.query<
      GetFileUploadConfigsResponse,
      GetFileUploadConfigsRequest
    >({
      query: (params) => ({
        url: `/file-upload-configs`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_FILE_UPLOAD_CONFIGS_TAG,
                id,
              })),
              { type: RTK_FILE_UPLOAD_CONFIGS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_FILE_UPLOAD_CONFIGS_TAG, id: "LIST" }],
    }),
    findFileUploadConfig: build.query<FindFileUploadConfigResponse, string>({
      query: (id) => ({
        url: `/file-upload-configs/${id}`,
        method: "GET",
      }),
      providesTags: (result, _, id) =>
        result ? [{ type: RTK_FILE_UPLOAD_CONFIGS_TAG, id }] : [],
    }),
    storeFileUploadConfig: build.mutation<
      StoreFileUploadConfigResponse,
      StoreFileUploadConfigRequest
    >({
      query: (data) => ({
        url: `/file-upload-configs`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: RTK_FILE_UPLOAD_CONFIGS_TAG, id: "LIST" },
              { type: RTK_FILE_UPLOAD_CONFIGS_CODE_TAG },
            ]
          : [],
    }),
    updateFileUploadConfig: build.mutation<
      UpdateFileUploadConfigResponse,
      UpdateFileUploadConfigRequest
    >({
      query: ({ id, data }) => ({
        url: `/file-upload-configs/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: RTK_FILE_UPLOAD_CONFIGS_TAG, id }] : [],
    }),
    deleteFileUploadConfig: build.mutation<void, string>({
      query: (id) => ({
        url: `/file-upload-configs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, id) =>
        result
          ? [
              { type: RTK_FILE_UPLOAD_CONFIGS_TAG, id },
              { type: RTK_FILE_UPLOAD_CONFIGS_CODE_TAG },
            ]
          : [],
    }),
  }),
});

export const {
  useFindFileUploadConfigByCodeQuery,
  useGetFileUploadConfigsQuery,
  useFindFileUploadConfigQuery,
  useStoreFileUploadConfigMutation,
  useUpdateFileUploadConfigMutation,
  useDeleteFileUploadConfigMutation,
} = apiFileUploadsConfig;

export default apiFileUploadsConfig;
