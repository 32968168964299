import { useAppSelector } from "@/store";
import { authorize, AuthorizationRule } from "@/utils/authorize";
import { useMemo } from "react";

export default function useAuthorizeRole(rules: AuthorizationRule[]) {
  const activeRole = useAppSelector((state) => state.auth.session.activeRole);
  const activeDealerRoles = useAppSelector(
    (state) => state.auth.session.activeDealerRoles,
  );

  const isAuthorized = useMemo(
    () => authorize({ activeRole: activeRole!, activeDealerRoles }, rules),
    [activeRole, activeDealerRoles, rules],
  );

  if (rules.length === 0) {
    return true;
  }

  if (!activeRole) {
    return false;
  }

  return isAuthorized;
}
