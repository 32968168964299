import RtkQueryService from "./RtkQueryService";

interface Dealer {
  id: string;
  code: string;
  name: string;
  address: string;
  logoUrl: string | null;
  createdAt: string;
  updatedAt: string;
}

interface GetDealersRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  groupIds: string[];
}

export interface GetDealersResponse {
  data: {
    data: (Dealer & { groupName: string })[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindDealerResponse {
  data: Dealer & {
    group: {
      id: string;
      name: string;
    };
    logo: {
      id: string;
      name: string;
      filename: string;
      size: number;
      mimeType: string;
    } | null;
  };
}

interface StoreDealerRequest {
  code: string;
  name: string;
  address: string;
  groupId?: string;
  logoFileId: string | null;
}

interface StoreDealerResponse {
  message: string;
}

interface UpdateDealerRequest {
  id: string;
  data: {
    code: string;
    name: string;
    address: string;
    groupId?: string;
    logoFileId?: string | null; // string = add/replace, null = remove, undefined = no changes
  };
}

interface UpdateDealerResponse {
  message: string;
}

interface GetDealersAdminsRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
}

export interface GetDealersAdminsResponse {
  data: {
    data: {
      userId: string;
      name: string;
      username: string;
      email: string;
      profilePhotoUrl: string;
      assignedAt: string;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindDealersAdminsUserAvailableRequest {
  email: string;
}

export interface FindDealersAdminsUserAvailableResponse {
  data: {
    userId: string;
    name: string;
    username: string;
    email: string;
    profilePhotoUrl: string;
  };
}

interface AssignDealerAdminRequest {
  userId: string;
}

interface AssignDealerAdminResponse {
  message: string;
}
interface UnassignDealerAdminResponse {
  message: string;
}

interface GetDealersEmployeesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
}

export interface GetDealersEmployeesResponse {
  data: {
    data: {
      userId: string;
      name: string;
      username: string;
      email: string;
      profilePhotoUrl: string;
      roles: string[];
      assignedAt: string;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

export const RTK_DEALERS_TAG = "Dealers" as const;
export const RTK_DEALERS_ADMINS_TAG = "DealersAdmins" as const;
export const RTK_DEALERS_EMPLOYEES_TAG = "DealersEmployees" as const;

const apiDealers = RtkQueryService.enhanceEndpoints({
  addTagTypes: [
    RTK_DEALERS_TAG,
    RTK_DEALERS_ADMINS_TAG,
    RTK_DEALERS_EMPLOYEES_TAG,
  ],
}).injectEndpoints({
  endpoints: (build) => ({
    getDealers: build.query<GetDealersResponse, GetDealersRequest>({
      query: (params) => ({
        url: `/dealers`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_DEALERS_TAG,
                id,
              })),
              { type: RTK_DEALERS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_DEALERS_TAG, id: "LIST" }],
    }),
    findDealer: build.query<FindDealerResponse, string>({
      query: (id) => ({
        url: `/dealers/${id}`,
        method: "GET",
      }),
      providesTags: (_, __, id) => [{ type: RTK_DEALERS_TAG, id }],
    }),
    storeDealer: build.mutation<StoreDealerResponse, StoreDealerRequest>({
      query: (data) => ({
        url: `/dealers`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RTK_DEALERS_TAG, id: "LIST" }] : [],
    }),
    updateDealer: build.mutation<UpdateDealerResponse, UpdateDealerRequest>({
      query: ({ id, data }) => ({
        url: `/dealers/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: RTK_DEALERS_TAG, id }] : [],
    }),
    deleteDealer: build.mutation<void, string>({
      query: (id) => ({
        url: `/dealers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, id) =>
        result ? [{ type: RTK_DEALERS_TAG, id }] : [],
    }),
    // ---
    getDealersAdmins: build.query<
      GetDealersAdminsResponse,
      { dealerId: string; params: GetDealersAdminsRequest }
    >({
      query: ({ dealerId, params }) => ({
        url: `/dealers/${dealerId}/admins`,
        method: "GET",
        params,
      }),
      providesTags: (result, _, { dealerId }) =>
        result
          ? [
              ...result.data.data.map(({ userId }) => ({
                type: RTK_DEALERS_ADMINS_TAG,
                id: `${dealerId}_${userId}`,
              })),
              { type: RTK_DEALERS_ADMINS_TAG, id: `LIST_${dealerId}` },
            ]
          : [{ type: RTK_DEALERS_ADMINS_TAG, id: `LIST_${dealerId}` }],
    }),
    getDealersAdminsUserAvailable: build.query<
      FindDealersAdminsUserAvailableResponse,
      { dealerId: string; params: FindDealersAdminsUserAvailableRequest }
    >({
      query: ({ dealerId, params }) => ({
        url: `/dealers/${dealerId}/admins/user-available`,
        method: "GET",
        params,
      }),
    }),
    assignDealerAdmin: build.mutation<
      AssignDealerAdminResponse,
      { dealerId: string; data: AssignDealerAdminRequest }
    >({
      query: ({ dealerId, data }) => ({
        url: `/dealers/${dealerId}/admins`,
        method: "POST",
        data,
      }),
      invalidatesTags: (result, _, { dealerId }) =>
        result
          ? [{ type: RTK_DEALERS_ADMINS_TAG, id: `LIST_${dealerId}` }]
          : [],
    }),
    unassignDealerAdmin: build.mutation<
      UnassignDealerAdminResponse,
      { dealerId: string; userId: string }
    >({
      query: ({ dealerId, userId }) => ({
        url: `/dealers/${dealerId}/admins/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, { dealerId, userId }) =>
        result
          ? [{ type: RTK_DEALERS_ADMINS_TAG, id: `${dealerId}_${userId}` }]
          : [],
    }),
    // ---
    getDealersEmployees: build.query<
      GetDealersEmployeesResponse,
      { dealerId: string; params: GetDealersEmployeesRequest }
    >({
      query: ({ dealerId, params }) => ({
        url: `/dealers/${dealerId}/employees`,
        method: "GET",
        params,
      }),
      providesTags: (result, _, { dealerId }) =>
        result
          ? [
              ...result.data.data.map(({ userId }) => ({
                type: RTK_DEALERS_EMPLOYEES_TAG,
                id: `${dealerId}_${userId}`,
              })),
              { type: RTK_DEALERS_EMPLOYEES_TAG, id: `LIST_${dealerId}` },
            ]
          : [{ type: RTK_DEALERS_EMPLOYEES_TAG, id: `LIST_${dealerId}` }],
    }),
  }),
});

export const {
  useGetDealersQuery,
  useFindDealerQuery,
  useStoreDealerMutation,
  useUpdateDealerMutation,
  useDeleteDealerMutation,
  // ---
  useGetDealersAdminsQuery,
  useLazyGetDealersAdminsUserAvailableQuery,
  useGetDealersAdminsUserAvailableQuery,
  useAssignDealerAdminMutation,
  useUnassignDealerAdminMutation,
  // ---
  useGetDealersEmployeesQuery,
} = apiDealers;

export default apiDealers;
