import RtkQueryService from "./RtkQueryService";

interface GetQueueJobsRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
}

export interface GetQueueJobsResponse {
  data: {
    data: {
      id: string;
      name: string;
      status: string;
      data: string | null;
      attempts: number;
      createdAt: string;
      delayUntil: string | null;
      startedAt: string | null;
      completedAt: string | null;
      failedAt: string | null;
      errorMessage: string | null;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

export const RTK_QUEUE_JOB_TAG = "QueueJob" as const;

const apiQueueJobs = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_QUEUE_JOB_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getQueueJobs: build.query<GetQueueJobsResponse, GetQueueJobsRequest>({
      query: (params) => ({
        url: `/queue-jobs`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_QUEUE_JOB_TAG,
                id,
              })),
              { type: RTK_QUEUE_JOB_TAG, id: "LIST" },
            ]
          : [{ type: RTK_QUEUE_JOB_TAG, id: "LIST" }],
    }),
  }),
});

export const { useGetQueueJobsQuery } = apiQueueJobs;

export default apiQueueJobs;
