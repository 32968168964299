import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE_BASE_NAME } from "./constants";
import { UserRole } from "@/types/role";

export type UserState = {
  id?: string;
  name?: string;
  username?: string;
  email?: string;
  profilePhotoUrl?: string | null;
  availableDealers?: {
    id: string;
    code: string;
    name: string;
    groupLogoLightUrl: string | null;
    groupLogoDarkUrl: string | null;
    mainRoles: UserRole[];
  }[];
};

const initialState: UserState = {};

const userSlice = createSlice({
  name: `${SLICE_BASE_NAME}/user`,
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState | null>) {
      if (!action.payload) {
        return initialState;
      }

      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
