import {
  RTK_VEHICLE_AGENCY_STOCK_TAG,
  RTK_VEHICLE_TAG,
} from "../VehicleService";
import RtkQueryService from "../RtkQueryService";
import { RTK_REPORT_TAG } from "../ReportService";

interface InfleetRequest {
  data: {
    apiUsed: boolean;
    sourceId: string;
    ict: boolean;
    saleTypeId: string;
    makeId: string;
    modelId?: string;
    modelCustom?: string;
    registration: string;
    mileage?: number;
    vin: string;
    shortChassis: string;
    commsNumber: string;
    note: string;
  };
}

interface InfleetResponse {
  message: string;
}

interface FormMetadataRequest {
  sourceId: string;
}

interface FormMetadataResponse {
  data: {
    field: string;
    mandatory: boolean | null; // null means the field is disabled
  }[];
}

export interface LookupVehicleRegistrationResponse {
  data: {
    apiResult: {
      make: string;
      model: string;
      vin: string;
      vinLast6: string;
    };
    make: {
      id: string;
      name: string;
      logoUrl: string | null;
    };
    model: {
      id: string;
      name: string;
    } | null;
  };
}

export interface CheckVehicleRegistrationResponse {
  data: {
    id: string;
    registration: string;
    makeName: string;
    makeLogoUrl: string | null;
    modelName: string;
  };
}

export interface CheckVehicleVinResponse {
  data: {
    id: string;
    vin: string;
    makeName: string;
    makeLogoUrl: string | null;
    modelName: string;
  };
}

export const RTK_STAGE_INFLEET_TAG = "StageInfleet" as const;

const apiInfleet = RtkQueryService.enhanceEndpoints({
  addTagTypes: [
    RTK_STAGE_INFLEET_TAG,
    RTK_VEHICLE_TAG,
    RTK_VEHICLE_AGENCY_STOCK_TAG,
    RTK_REPORT_TAG,
  ],
}).injectEndpoints({
  endpoints: (build) => ({
    infleet: build.mutation<InfleetResponse, InfleetRequest>({
      query: ({ data }) => ({
        url: `/stage/infleet`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: [
        { type: RTK_VEHICLE_TAG },
        { type: RTK_VEHICLE_AGENCY_STOCK_TAG },
        { type: RTK_REPORT_TAG, id: "TODAY" },
      ],
    }),
    getInfleetFormMetadata: build.query<
      FormMetadataResponse,
      FormMetadataRequest
    >({
      query: (params) => ({
        url: `/stage/infleet/form-metadata`,
        method: "GET",
        params: params,
      }),
      providesTags: (result) =>
        result ? [{ type: RTK_STAGE_INFLEET_TAG, id: "FORM_METADATA" }] : [],
    }),
    lookupRegistration: build.query<LookupVehicleRegistrationResponse, string>({
      query: (registration) => ({
        url: `/stage/infleet/registration/${registration}/lookup`,
        method: "GET",
      }),
    }),
    checkRegistration: build.query<CheckVehicleRegistrationResponse, string>({
      query: (registration) => ({
        url: `/stage/infleet/registration/${registration}/check`,
        method: "GET",
      }),
    }),
    checkVin: build.query<CheckVehicleVinResponse, string>({
      query: (vin) => ({
        url: `/stage/infleet/vin/${vin}/check`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useInfleetMutation,
  useGetInfleetFormMetadataQuery,
  useLazyLookupRegistrationQuery,
  useLazyCheckRegistrationQuery,
  useLazyCheckVinQuery,
} = apiInfleet;

export default apiInfleet;
