import { NavigationTree } from "@/@types/navigation";
import { NAV_ITEM_TYPE_ITEM } from "@/constants/navigation.constant";

export const navigationConfig: NavigationTree[] = [
  {
    key: "home",
    title: "Overview",
    icon: "home",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "makes",
    title: "Makes",
    icon: "make",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "dealer-groups",
    title: "Dealer Groups",
    icon: "dealerGroup",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "dealers",
    title: "Dealers",
    icon: "dealer",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "users",
    title: "Users",
    icon: "user",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "file-configs",
    title: "File Configs",
    icon: "fileConfig",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "queue-jobs",
    title: "Queue Jobs",
    icon: "queueJob",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "dealer.makes",
    title: "Makes",
    icon: "make",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "dealer.employees",
    title: "Employees",
    icon: "employee",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "vehicles",
    title: "Vehicles",
    icon: "vehicle",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "stages.infleet",
    title: "Infleet",
    icon: "stageInfleet",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "stages.admin",
    title: "Admin",
    icon: "stageAdmin",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "stages.ict",
    title: "ICT",
    icon: "stageIct",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "stages.mechanical-prep",
    title: "Mechanical Prep",
    icon: "stageMechanicalPrep",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "stages.body-damage",
    title: "Body Damage",
    icon: "stageBodyDamage",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "stages.media",
    title: "Media",
    icon: "stageMedia",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "stages.sale",
    title: "Sale",
    icon: "stageSale",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "vehicles.agency-stock",
    title: "Agency Stock",
    icon: "agencyStock",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "vehicles.crm-confirmations",
    title: "CRM Confirmation",
    icon: "crmConfirmation",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
  {
    key: "site-settings",
    title: "Site Settings",
    icon: "siteSetting",
    type: NAV_ITEM_TYPE_ITEM,
    subMenu: [],
  },
];
