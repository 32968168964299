import RtkQueryService from "./RtkQueryService";

interface GetUserDeviceRequest {
  data: {
    id: string;
    identifier: string;
    token?: string;
    type?: string;
    tokenIssuedAt?: string;
  };
}

interface UpdateUserDeviceRequest {
  token: string;
}

interface UpdateUserDeviceResponse {
  message: string;
}

export const RTK_USER_DEVICE_TAG = "UserDevice" as const;

const apiActiveUserDevice = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_USER_DEVICE_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getActiveUserDevice: build.query<GetUserDeviceRequest, void>({
      query: () => ({
        url: "/active-user-device",
        method: "GET",
      }),
      providesTags: [RTK_USER_DEVICE_TAG],
    }),
    updateActiveUserDevice: build.mutation<
      UpdateUserDeviceResponse,
      UpdateUserDeviceRequest
    >({
      query: (data) => ({
        url: "/active-user-device",
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [RTK_USER_DEVICE_TAG],
    }),
  }),
});

export const {
  useGetActiveUserDeviceQuery,
  useUpdateActiveUserDeviceMutation,
} = apiActiveUserDevice;

export default apiActiveUserDevice;
