import RtkQueryService from "./RtkQueryService";

interface DealerGroup {
  id: string;
  name: string;
  logoLightUrl: string | null;
  logoDarkUrl: string | null;
  createdAt: string;
}

interface GetDealerGroupsRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
}

export interface GetDealerGroupsResponse {
  data: {
    data: DealerGroup[];
    totalCount: number;
    filteredCount: number;
  };
}

export interface GetDealerGroupOptionsResponse {
  data: {
    id: string;
    name: string;
  }[];
}

interface FindDealerGroupResponse {
  data: DealerGroup & {
    logoLight: {
      id: string;
      name: string;
      filename: string;
      size: number;
      mimeType: string;
    } | null;
    logoDark: {
      id: string;
      name: string;
      filename: string;
      size: number;
      mimeType: string;
    } | null;
  };
}

interface StoreDealerGroupRequest {
  name: string;
  logoLightFileId: string | null;
  logoDarkFileId: string | null;
}

interface StoreDealerGroupResponse {
  message: string;
}

interface UpdateDealerGroupRequest {
  id: string;
  data: {
    name: string;
    logoLightFileId?: string | null; // string = add/replace, null = remove, undefined = no changes
    logoDarkFileId?: string | null; // string = add/replace, null = remove, undefined = no changes
  };
}

interface UpdateDealerGroupResponse {
  message: string;
}

export const RTK_DEALER_GROUPS_TAG = "DealerGroups" as const;
export const RTK_DEALER_GROUP_OPTIONS_TAG = "DealerGroupOptions" as const;

const apiDealerGroups = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_DEALER_GROUPS_TAG, RTK_DEALER_GROUP_OPTIONS_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getDealerGroups: build.query<
      GetDealerGroupsResponse,
      GetDealerGroupsRequest
    >({
      query: (params) => ({
        url: `/dealer-groups`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_DEALER_GROUPS_TAG,
                id,
              })),
              { type: RTK_DEALER_GROUPS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_DEALER_GROUPS_TAG, id: "LIST" }],
    }),
    getDealerGroupOptions: build.query<GetDealerGroupOptionsResponse, void>({
      query: () => ({
        url: `/options/dealer-groups`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_DEALER_GROUP_OPTIONS_TAG,
                id,
              })),
              { type: RTK_DEALER_GROUP_OPTIONS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_DEALER_GROUP_OPTIONS_TAG, id: "LIST" }],
    }),
    findDealerGroup: build.query<FindDealerGroupResponse, string>({
      query: (id) => ({
        url: `/dealer-groups/${id}`,
        method: "GET",
      }),
      providesTags: (result, _, id) =>
        result ? [{ type: RTK_DEALER_GROUPS_TAG, id }] : [],
    }),
    storeDealerGroup: build.mutation<
      StoreDealerGroupResponse,
      StoreDealerGroupRequest
    >({
      query: (data) => ({
        url: `/dealer-groups`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: RTK_DEALER_GROUPS_TAG, id: "LIST" },
              { type: RTK_DEALER_GROUP_OPTIONS_TAG, id: "LIST" },
            ]
          : [],
    }),
    updateDealerGroup: build.mutation<
      UpdateDealerGroupResponse,
      UpdateDealerGroupRequest
    >({
      query: ({ id, data }) => ({
        url: `/dealer-groups/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { id }) =>
        result
          ? [
              { type: RTK_DEALER_GROUPS_TAG, id },
              { type: RTK_DEALER_GROUP_OPTIONS_TAG, id },
            ]
          : [],
    }),
    deleteDealerGroup: build.mutation<void, string>({
      query: (id) => ({
        url: `/dealer-groups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, id) =>
        result
          ? [
              { type: RTK_DEALER_GROUPS_TAG, id },
              { type: RTK_DEALER_GROUP_OPTIONS_TAG, id },
            ]
          : [],
    }),
  }),
});

export const {
  useGetDealerGroupsQuery,
  useGetDealerGroupOptionsQuery,
  useFindDealerGroupQuery,
  useStoreDealerGroupMutation,
  useUpdateDealerGroupMutation,
  useDeleteDealerGroupMutation,
} = apiDealerGroups;

export default apiDealerGroups;
