import { DealerRole, UserRole } from "@/types/role";

export type AuthorizationRule = UserRole | [UserRole, DealerRole[]];

export function authorize(
  {
    activeRole,
    activeDealerRoles,
  }: { activeRole: UserRole; activeDealerRoles?: DealerRole[] },
  rules: AuthorizationRule[],
) {
  return rules.some((allowedRole) => {
    if (Array.isArray(allowedRole)) {
      const [role, dealerRoles] = allowedRole;
      return (
        role === activeRole &&
        dealerRoles.some((dealerRole) =>
          activeDealerRoles?.includes(dealerRole),
        )
      );
    }

    return allowedRole === activeRole;
  });
}
