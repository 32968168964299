import flag from "@/assets/images/flags/uk.png";
import { cn } from "@/lib/utils";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface BaseProps {
  children?: React.ReactNode;
  className?: string;
  showDotIndicator?: boolean;
}

interface VehiclePlateDivProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: "div";
}

interface VehiclePlateButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as: "button";
}

interface VehiclePlateLinkProps extends LinkProps {
  as: "link";
}

type VehiclePlateProps = BaseProps &
  (VehiclePlateDivProps | VehiclePlateButtonProps | VehiclePlateLinkProps);

const VehiclePlate = ({
  as = "div",
  children,
  className,
  showDotIndicator,
  ...props
}: VehiclePlateProps) => {
  const commonProps = {
    className: cn(
      "relative border-2 border-slate-800 rounded-md flex !h-7 !w-24",
      className,
    ),
    ...props,
  };

  const Children = () => (
    <>
      <div className="flex flex-col items-center justify-center space-y-1 rounded-l-sm bg-[#244d8a] px-[1px]">
        <img src={flag} alt="UK Flag" className="w-2" />
        <span className="text-[5px] font-bold text-[#ffcc26]">GB</span>
      </div>
      <div className="font-charles-wright flex flex-grow items-center justify-center whitespace-nowrap rounded-r-sm bg-[#ffcc26] pt-1 font-bold text-black">
        {children}
      </div>

      {showDotIndicator && (
        <div className="absolute -right-1.5 -top-1.5 h-3 w-3 rounded-full border-[1px] border-white bg-red-500" />
      )}
    </>
  );

  if (as === "link") {
    return (
      <Link {...(commonProps as LinkProps)}>
        <Children />
      </Link>
    );
  }

  if (as === "button") {
    return (
      <button
        {...(commonProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        <Children />
      </button>
    );
  }

  return (
    <div {...(commonProps as React.HTMLAttributes<HTMLDivElement>)}>
      <Children />
    </div>
  );
};

// TODO: fix flexbox issue and merge with the VehiclePlate component above
VehiclePlate.TableVersion = ({
  as = "div",
  children,
  className,
  showDotIndicator,
  ...props
}: VehiclePlateProps) => {
  const commonProps = {
    className: cn(
      "relative border-2 border-slate-800 rounded-md flex !h-7 !w-24",
      className,
    ),
    ...props,
  };

  const Children = () => (
    <>
      <div className="flex flex-col -space-y-1 rounded-l-sm bg-[#244d8a] px-[1px]">
        <img src={flag} alt="UK Flag" className="mt-1.5 w-2" />
        <span className="text-[5px] font-bold text-[#ffcc26]">GB</span>
      </div>
      <div className="font-charles-wright flex flex-grow items-center justify-center whitespace-nowrap rounded-r-sm bg-[#ffcc26] pt-1 font-bold text-black">
        {children}
      </div>

      {showDotIndicator && (
        <div className="absolute -right-1.5 -top-1.5 h-3 w-3 rounded-full border-[1px] border-white bg-red-500" />
      )}
    </>
  );

  if (as === "link") {
    return (
      <Link
        {...(commonProps as LinkProps)}
        onClick={(e) => e.stopPropagation()}
      >
        <Children />
      </Link>
    );
  }

  if (as === "button") {
    return (
      <button
        {...(commonProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        onClick={(e) => {
          e.stopPropagation();
          // TODO: fix this
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return commonProps?.onClick?.(e);
        }}
      >
        <Children />
      </button>
    );
  }

  return (
    <div {...(commonProps as React.HTMLAttributes<HTMLDivElement>)}>
      <Children />
    </div>
  );
};

export default VehiclePlate;
