import { DealerRole, UserRole } from "@/types/role";
import RtkQueryService from "./RtkQueryService";

interface GetUserProfileResponse {
  data: {
    id: string;
    name: string;
    username: string;
    email: string;
    profilePhotoUrl: string | null;
    availableDealers: {
      id: string;
      code: string;
      name: string;
      groupLogoLightUrl: string | null;
      groupLogoDarkUrl: string | null;
      mainRoles: UserRole[];
    }[];
  };
}

interface UpdateUserProfilePhotoRequest {
  fileId: string;
}

interface UpdateUserProfilePhotoResponse {
  message: string;
}

interface UpdateUserProfileRequest {
  name: string;
}

interface UpdateUserProfileResponse {
  message: string;
}

interface UpdateUserUsernameRequest {
  username: string;
}

interface UpdateUserUsernameResponse {
  message: string;
}

interface UpdateUserEmailRequest {
  email: string;
}

interface UpdateUserEmailResponse {
  message: string;
}

interface UpdateUserPasswordRequest {
  oldPassword: string;
  newPassword: string;
}

interface UpdateUserPasswordResponse {
  message: string;
}

interface SwitchRoleRequest {
  newRole: UserRole;
  refreshToken: string;
}

interface SwitchRoleResponse {
  data: {
    activeDealerId: string | null;
    activeRole: UserRole;
    activeDealerRoles: DealerRole[];
    accessToken: string;
    refreshToken: string;
  };
}

interface SwitchDealerRequest {
  newDealerId: string;
  newRole: UserRole;
  refreshToken: string;
}

interface SwitchDealerResponse {
  data: {
    activeDealerId: string | null;
    activeRole: UserRole;
    activeDealerRoles: DealerRole[];
    accessToken: string;
    refreshToken: string;
  };
}

export const RTK_USER_TAG = "User" as const;

const apiUser = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_USER_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<GetUserProfileResponse, void>({
      query: () => ({
        url: "/user/profile",
        method: "GET",
      }),
      providesTags: [RTK_USER_TAG],
    }),
    updateUserProfilePhoto: build.mutation<
      UpdateUserProfilePhotoResponse,
      UpdateUserProfilePhotoRequest
    >({
      query: (data) => ({
        url: "/user/photo",
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [RTK_USER_TAG],
    }),
    updateUserProfile: build.mutation<
      UpdateUserProfileResponse,
      UpdateUserProfileRequest
    >({
      query: (data) => ({
        url: "/user/profile",
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [RTK_USER_TAG],
    }),
    updateUserUsername: build.mutation<
      UpdateUserUsernameResponse,
      UpdateUserUsernameRequest
    >({
      query: (data) => ({
        url: "/user/username",
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [RTK_USER_TAG],
    }),
    updateUserEmail: build.mutation<
      UpdateUserEmailResponse,
      UpdateUserEmailRequest
    >({
      query: (data) => ({
        url: "/user/email",
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [RTK_USER_TAG],
    }),
    updateUserPassword: build.mutation<
      UpdateUserPasswordResponse,
      UpdateUserPasswordRequest
    >({
      query: (data) => ({
        url: "/user/password",
        method: "PATCH",
        data: data,
      }),
    }),
    // Should not invalidate tags because it has a new access token
    switchRole: build.mutation<SwitchRoleResponse, SwitchRoleRequest>({
      query: (data) => ({
        url: "/user/switch-role",
        method: "POST",
        data: data,
      }),
    }),
    // Should not invalidate tags because it has a new access token
    switchDealer: build.mutation<SwitchDealerResponse, SwitchDealerRequest>({
      query: (data) => ({
        url: "/user/switch-dealer",
        method: "POST",
        data: data,
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useUpdateUserProfilePhotoMutation,
  useUpdateUserProfileMutation,
  useUpdateUserUsernameMutation,
  useUpdateUserEmailMutation,
  useUpdateUserPasswordMutation,
  useSwitchRoleMutation,
  useSwitchDealerMutation,
} = apiUser;

export default apiUser;
