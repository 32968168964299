import { cn } from "@/lib/utils";
import logoDefault from "@/assets/features/makes/logo-default.svg";

interface MakeLogoProps {
  src: string | null;
  alt: string;
  className?: string;
}

const MakeLogo = ({ src, alt, className = "" }: MakeLogoProps) => (
  <img
    src={src || logoDefault}
    alt={alt}
    className={cn("h-8 max-w-12", className)}
  />
);

export default MakeLogo;
