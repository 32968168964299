import { useAppSelector } from "@/store";
import { AuthorizationRule, authorize } from "@/utils/authorize";
import { useCallback } from "react";

export default function useCheckRolePermission() {
  const { authenticated, activeRole, activeDealerRoles } = useAppSelector(
    (state) => state.auth.session,
  );

  const check = useCallback(
    (rules: AuthorizationRule[]) => {
      if (!authenticated || !activeRole) return false;
      return authorize({ activeRole, activeDealerRoles }, rules);
    },
    [activeDealerRoles, activeRole, authenticated],
  );

  return { check };
}
