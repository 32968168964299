import { protectedRoutes } from "@/configs/routes";
import authRoute from "@/configs/routes/authRoute";
import { matchRoutes, useLocation } from "react-router-dom";

const routes = [...authRoute, ...protectedRoutes];

/**
 * Custom hook that returns the active route based on the current location.
 * @returns The active route object.
 */
export default function useActiveRoute() {
  const location = useLocation();
  const route = matchRoutes(routes, location.pathname);

  return route;
}
