import useAuthorizeRole from "@/hooks/useAuthorizeRole";
import ForbiddenInfo from "./ForbiddenInfo";
import { AuthorizationRule } from "@/utils/authorize";

interface AuthorityRouteProps {
  authority: AuthorizationRule[];
  element: React.ReactNode;
}

const Authorize = ({ authority, element }: AuthorityRouteProps) => {
  const authorized = useAuthorizeRole(authority);

  if (!authorized) {
    return <ForbiddenInfo />;
  }

  return <>{element}</>;
};

export default Authorize;
