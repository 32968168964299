import { createContext, useContext } from "react";
import type { Socket } from "socket.io-client";

interface SocketIoContextValue {
  socket: Socket | null;
}

const SocketIoContext = createContext<SocketIoContextValue>(
  {} as SocketIoContextValue,
);

export const useSocketIo = () => useContext(SocketIoContext);

export default SocketIoContext;
