import RtkQueryService from "./RtkQueryService";
import {
  ConfirmVehicleCrmRequest,
  ConfirmVehicleCrmResponse,
  FindVehicleCrmConfirmationResponse,
  GetVehicleCrmConfirmationRequest,
  GetVehicleCrmConfirmationResponse,
} from "./VehicleCrmConfirmationService.types";

const RTK_VEHICLE_CRM_CONFIRMATION_TAG =
  "RTK_VEHICLE_CRM_CONFIRMATION_TAG" as const;

const apiVehicleCrmConfirmation = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_VEHICLE_CRM_CONFIRMATION_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getVehicleCrmConfirmations: build.query<
      GetVehicleCrmConfirmationResponse,
      GetVehicleCrmConfirmationRequest
    >({
      query: (params) => ({
        url: `/vehicle-crm-confirmations`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_VEHICLE_CRM_CONFIRMATION_TAG,
                id: id,
              })),
              { type: RTK_VEHICLE_CRM_CONFIRMATION_TAG, id: "LIST" },
            ]
          : [{ type: RTK_VEHICLE_CRM_CONFIRMATION_TAG, id: "LIST" }],
    }),
    findVehicleCrmConfirmations: build.query<
      FindVehicleCrmConfirmationResponse,
      string
    >({
      query: (id) => ({
        url: `/vehicle-crm-confirmations/${id}`,
        method: "GET",
      }),
      providesTags: (result, _, id) =>
        result ? [{ type: RTK_VEHICLE_CRM_CONFIRMATION_TAG, id }] : [],
    }),
    confirmVehicleCrm: build.mutation<
      ConfirmVehicleCrmResponse,
      { vehicleId: string; data: ConfirmVehicleCrmRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/vehicle-crm-confirmations/${vehicleId}/confirm`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [{ type: RTK_VEHICLE_CRM_CONFIRMATION_TAG, id: vehicleId }]
          : [],
    }),
  }),
});

export const {
  useGetVehicleCrmConfirmationsQuery,
  useFindVehicleCrmConfirmationsQuery,
  useConfirmVehicleCrmMutation,
} = apiVehicleCrmConfirmation;

export default apiVehicleCrmConfirmation;
