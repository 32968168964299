import RtkQueryService from "./RtkQueryService";

interface Role {
  id: string;
  code: string;
  name: string;
}

export interface GetRoleOptionsResponse {
  data: Role[];
}

export const RTK_ROLES_TAG = "Roles" as const;
export const RTK_ROLE_OPTIONS_TAG = "RoleOptions" as const;

const apiRole = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_ROLES_TAG, RTK_ROLE_OPTIONS_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getRoleOptions: build.query<GetRoleOptionsResponse, void>({
      query: () => ({
        url: `/options/dealer-roles`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_ROLE_OPTIONS_TAG,
                id,
              })),
              { type: RTK_ROLE_OPTIONS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_ROLE_OPTIONS_TAG, id: "LIST" }],
    }),
  }),
});

export const { useGetRoleOptionsQuery } = apiRole;

export default apiRole;
