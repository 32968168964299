import RtkQueryService from "../RtkQueryService";

interface GetActiveDealerGroupDealersResponse {
  data: {
    id: string;
    code: string;
    name: string;
  }[];
}

export const RTK_ACTIVE_DEALER_GROUP_OPTION_DEALER_TAG =
  "ActiveDealerGroupOptionDealer" as const;

const apiActiveDealerGroupDealer = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_ACTIVE_DEALER_GROUP_OPTION_DEALER_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getActiveDealerGroupDealers: build.query<
      GetActiveDealerGroupDealersResponse,
      void
    >({
      query: (params) => ({
        url: `/options/active-dealer-group/dealers`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_ACTIVE_DEALER_GROUP_OPTION_DEALER_TAG,
                id: id,
              })),
              { type: RTK_ACTIVE_DEALER_GROUP_OPTION_DEALER_TAG, id: "LIST" },
            ]
          : [{ type: RTK_ACTIVE_DEALER_GROUP_OPTION_DEALER_TAG, id: "LIST" }],
    }),
  }),
});

export const { useGetActiveDealerGroupDealersQuery } =
  apiActiveDealerGroupDealer;

export default apiActiveDealerGroupDealer;
