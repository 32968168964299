import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PublicRoute from "./components/router/PublicRoute";
import ProtectedRoute from "./components/router/ProtectedRoute";
import DashboardLayout from "./components/layout/DashboardLayout";
import AuthLayout from "./components/layout/AuthLayout";
import { Toaster } from "./components/ui/Sonner";
import PageNotFoundInfo from "./components/shared/PageNotFoundInfo";
import { protectedRoutes, publicRoutes } from "./configs/routes";
import Authorize from "./components/shared/Authorize";
import { Loader2Icon } from "lucide-react";

const DocsPage = lazy(() => import("./pages/DocsPage"));

const App = () => {
  return (
    <>
      <Suspense
        fallback={
          <div className="flex h-screen items-center justify-center">
            <Loader2Icon className="h-8 w-8 animate-spin" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route element={<AuthLayout />}>
              {publicRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Route>
          </Route>
          <Route path="/docs" element={<ProtectedRoute />}>
            <Route index element={<DocsPage />} />
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            <Route element={<DashboardLayout />}>
              {protectedRoutes.map((route) => (
                <Route
                  key={route.key}
                  index={route.path === "/"}
                  path={route.path}
                  element={
                    <Authorize
                      authority={route.authority}
                      element={<route.component />}
                    />
                  }
                />
              ))}
              <Route path="/*" element={<PageNotFoundInfo />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
      <Toaster />
    </>
  );
};

export default App;
