import { MoonStarIcon, SunIcon, SunMoonIcon } from "lucide-react";
import { useTheme } from "../context/ThemeContext";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/Dialog";
import { Label } from "../ui/Label";
import { RadioGroup, RadioGroupItem } from "../ui/RadioGroup";
import { cn } from "@/lib/utils";
import { Button } from "../ui/Button";

interface ChangeThemeDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChangeThemeDialog = ({ open, onClose }: ChangeThemeDialogProps) => {
  const { theme, onChangeTheme } = useTheme();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-sm">
        <DialogHeader>
          <DialogTitle>Theme</DialogTitle>
          <DialogDescription>
            Change the theme of the application
          </DialogDescription>
        </DialogHeader>
        <div>
          <RadioGroup
            value={theme}
            onValueChange={onChangeTheme}
            className="grid grid-cols-3 gap-3"
          >
            <div>
              <RadioGroupItem
                value="light"
                id="theme-light"
                className="peer sr-only"
                checked={theme === "light"}
              />
              <Label
                htmlFor="theme-light"
                className={cn(
                  "flex flex-col items-center justify-between gap-2 rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                  theme === "light" && "bg-primary/10 hover:bg-primary/10",
                )}
              >
                <SunIcon className="h-5 w-5" />
                Light
              </Label>
            </div>
            <div>
              <RadioGroupItem
                value="dark"
                id="theme-dark"
                className="peer sr-only"
                checked={theme === "dark"}
              />
              <Label
                htmlFor="theme-dark"
                className={cn(
                  "flex flex-col items-center justify-between gap-2 rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                  theme === "dark" && "bg-primary/10 hover:bg-primary/10",
                )}
              >
                <MoonStarIcon className="h-5 w-5" />
                Dark
              </Label>
            </div>
            <div>
              <RadioGroupItem
                value="system"
                id="theme-system"
                className="peer sr-only"
                checked={theme === "system"}
              />
              <Label
                htmlFor="theme-system"
                className={cn(
                  "flex flex-col items-center justify-between gap-2 rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                  theme === "system" && "bg-primary/10 hover:bg-primary/10",
                )}
              >
                <SunMoonIcon className="h-5 w-5" />
                System
              </Label>
            </div>
          </RadioGroup>
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={onClose}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeThemeDialog;
