import { useLazyGetUserProfileQuery } from "@/services/UserService";
import { setUser, useAppDispatch } from "@/store";
import { useCallback } from "react";
import { toast } from "sonner";
import useDidUpdate from "./useDidUpdate";
import { handleAxiosError } from "@/utils/httpError";

const useRefreshUser = () => {
  const dispatch = useAppDispatch();
  const [getProfile, result] = useLazyGetUserProfileQuery();

  useDidUpdate(() => {
    if (result.data) {
      dispatch(setUser(result.data.data));
    }
  }, [result.data]);

  const callback = useCallback(async () => {
    try {
      await getProfile().unwrap();
    } catch (err: unknown) {
      handleAxiosError(err, (message) => {
        toast.error("Failed to refresh user.", {
          description: message,
        });
      });
    }
  }, [getProfile]);

  return callback;
};

export default useRefreshUser;
