import useActiveRoute from "@/hooks/useActiveRoute";
import { Helmet as ReactHelmet } from "react-helmet";

const Helmet = () => {
  const route = useActiveRoute();
  const meta = route?.[0].route.meta;

  return (
    <ReactHelmet>
      {meta ? <title>{meta.title} - CarOps</title> : <title>CarOps</title>}
    </ReactHelmet>
  );
};

export default Helmet;
