import { RTK_REPORT_TAG } from "../ReportService";
import RtkQueryService from "../RtkQueryService";
import {
  RTK_VEHICLE_AGENCY_STOCK_TAG,
  RTK_VEHICLE_TAG,
} from "../VehicleService";

interface MessageResponse {
  message: string;
}

interface GetStageSaleVehiclesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  source: string[];
  saleType: string[];
  make: string[];
  status: string[];
}

export interface GetStageSaleVehiclesResponse {
  data: {
    data: {
      id: string;
      modelName: string | null;
      modelCustom: string | null;
      registration: string | null;
      sourceName: string;
      saleTypeName: string | null;
      makeName: string | null;
      makeLogoUrl: string | null;
      mileage?: number;
      shortChassis: number;
      stage: string;
      stageStatus: string;
      createdAt: string;
      lastUpdatedAt: string;
      isViewed: boolean;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindStageSaleVehicleResponse {
  data: {
    price: string | null;
    links: {
      id: string;
      description: string;
      url: string;
      user: {
        id: string;
        name: string;
      } | null;
      createdAt: string;
    }[];
    collectionDate: string | null;
    note: string;
  };
}

interface FindStageSoldDirectSaleVehicleResponse {
  data: {
    collectionDate: string | null;
    note: string | null;
  };
}

interface StorePostLinkStageSaleRequest {
  description: string;
  url: string;
}

interface UpdatePostLinkStageSaleRequest {
  description: string;
  url: string;
}

interface SaveDraftPostStageSaleRequest {
  price: number | null;
  note: string;
}

interface PostStageSaleRequest extends SaveDraftPostStageSaleRequest {}

interface SaveDraftSoldStageSaleRequest {
  collectionDate: string;
  note: string;
}

interface SoldStageSaleRequest extends SaveDraftSoldStageSaleRequest {}

interface SaveDraftSoldDirectStageSaleRequest
  extends SaveDraftSoldStageSaleRequest {}

interface SoldDirectStageSaleRequest
  extends SaveDraftSoldDirectStageSaleRequest {}

export const RTK_STAGE_SALE_TAG = "StageSale" as const;
export const RTK_STAGE_SALE_SOLD_DIRECT_TAG = "StageSaleSoldDirect" as const;

const apiStageSale = RtkQueryService.enhanceEndpoints({
  addTagTypes: [
    RTK_STAGE_SALE_TAG,
    RTK_STAGE_SALE_SOLD_DIRECT_TAG,
    RTK_VEHICLE_TAG,
    RTK_VEHICLE_AGENCY_STOCK_TAG,
    RTK_REPORT_TAG,
  ],
}).injectEndpoints({
  endpoints: (build) => ({
    getStageSaleVehicles: build.query<
      GetStageSaleVehiclesResponse,
      GetStageSaleVehiclesRequest
    >({
      query: (params) => ({
        url: `/stage/sale`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_STAGE_SALE_TAG,
                id: id,
              })),
              { type: RTK_STAGE_SALE_TAG, id: "LIST" },
            ]
          : [{ type: RTK_STAGE_SALE_TAG, id: "LIST" }],
    }),
    findStageSaleVehicle: build.query<FindStageSaleVehicleResponse, string>({
      query: (vehicleId) => ({
        url: `/stage/sale/${vehicleId}`,
        method: "GET",
      }),
      providesTags: (result, _, vehicleId) =>
        result ? [{ type: RTK_STAGE_SALE_TAG, id: vehicleId }] : [],
    }),
    // ---
    storePostLinkStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; data: StorePostLinkStageSaleRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/sale/${vehicleId}/post/links`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
            ]
          : [],
    }),
    updatePostLinkStageSale: build.mutation<
      MessageResponse,
      {
        vehicleId: string;
        linkId: string;
        data: UpdatePostLinkStageSaleRequest;
      }
    >({
      query: ({ vehicleId, linkId, data }) => ({
        url: `/stage/sale/${vehicleId}/post/links/${linkId}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
            ]
          : [],
    }),
    deletePostLinkStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; linkId: string }
    >({
      query: ({ vehicleId, linkId }) => ({
        url: `/stage/sale/${vehicleId}/post/links/${linkId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
            ]
          : [],
    }),
    // ---
    saveDraftPostStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SaveDraftPostStageSaleRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/sale/${vehicleId}/post/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
            ]
          : [],
    }),
    postStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; data: PostStageSaleRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/sale/${vehicleId}/post`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    //
    saveDraftSoldStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SaveDraftSoldStageSaleRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/sale/${vehicleId}/sold/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
            ]
          : [],
    }),
    soldStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SoldStageSaleRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/sale/${vehicleId}/sold`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_VEHICLE_AGENCY_STOCK_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    // ---
    findStageSoldDirectSaleVehicle: build.query<
      FindStageSoldDirectSaleVehicleResponse,
      string
    >({
      query: (vehicleId) => ({
        url: `/stage/sale/${vehicleId}/sold/direct`,
        method: "GET",
      }),
      providesTags: (result, _, vehicleId) =>
        result ? [{ type: RTK_STAGE_SALE_SOLD_DIRECT_TAG, id: vehicleId }] : [],
    }),
    saveDraftSoldDirectStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SaveDraftSoldDirectStageSaleRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/sale/${vehicleId}/sold/direct/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_SOLD_DIRECT_TAG, id: vehicleId },
            ]
          : [],
    }),
    soldDirectStageSale: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SoldDirectStageSaleRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/sale/${vehicleId}/sold/direct`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_VEHICLE_AGENCY_STOCK_TAG, id: vehicleId },
              { type: RTK_STAGE_SALE_SOLD_DIRECT_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
  }),
});

export const {
  useGetStageSaleVehiclesQuery,
  useFindStageSaleVehicleQuery,
  //
  useStorePostLinkStageSaleMutation,
  useUpdatePostLinkStageSaleMutation,
  useDeletePostLinkStageSaleMutation,
  //
  useSaveDraftPostStageSaleMutation,
  usePostStageSaleMutation,
  //
  useSaveDraftSoldStageSaleMutation,
  useSoldStageSaleMutation,
  //
  useFindStageSoldDirectSaleVehicleQuery,
  useSaveDraftSoldDirectStageSaleMutation,
  useSoldDirectStageSaleMutation,
} = apiStageSale;

export default apiStageSale;
