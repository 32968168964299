import { RTK_VEHICLE_TAG } from "../VehicleService";
import RtkQueryService from "../RtkQueryService";
import { RTK_REPORT_TAG } from "../ReportService";

interface GetVehiclesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  source: string[];
  saleType: string[];
  make: string[];
}

export interface GetStageIctVehiclesResponse {
  data: {
    data: {
      id: string;
      modelName: string | null;
      modelCustom: string | null;
      registration: string | null;
      sourceName: string;
      saleTypeName: string | null;
      makeName: string | null;
      makeLogoUrl: string | null;
      mileage?: number;
      shortChassis: string;
      stage: string;
      stageStatus: string;
      createdAt: string;
      lastUpdatedAt: string;
      isViewed: boolean;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindResponse {
  data: {
    viewedAndSold: boolean;
    ict: boolean;
    note: string;
  };
}

interface IctRequest {
  viewedAndSold?: boolean;
  ict?: boolean;
  note: string;
}

interface IctResponse {
  message: string;
}

export const RTK_STAGE_ICT_TAG = "StageIct" as const;

const apiStageIct = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_STAGE_ICT_TAG, RTK_VEHICLE_TAG, RTK_REPORT_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getStageIctVehicles: build.query<
      GetStageIctVehiclesResponse,
      GetVehiclesRequest
    >({
      query: (params) => ({
        url: `/stage/ict`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_STAGE_ICT_TAG,
                id: id,
              })),
              { type: RTK_STAGE_ICT_TAG, id: "LIST" },
            ]
          : [{ type: RTK_STAGE_ICT_TAG, id: "LIST" }],
    }),
    findStageIctVehicle: build.query<FindResponse, string>({
      query: (vehicleId) => ({
        url: `/stage/ict/${vehicleId}`,
        method: "GET",
      }),
      providesTags: (result, _, vehicleId) =>
        result ? [{ type: RTK_STAGE_ICT_TAG, id: vehicleId }] : [],
    }),
    saveDraftStageIctIct: build.mutation<
      IctResponse,
      { vehicleId: string; data: IctRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/ict/${vehicleId}/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_ICT_TAG, id: vehicleId },
            ]
          : [],
    }),
    stageIctIct: build.mutation<
      IctResponse,
      { vehicleId: string; data: IctRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/ict/${vehicleId}`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_ICT_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
  }),
});

export const {
  useGetStageIctVehiclesQuery,
  useFindStageIctVehicleQuery,
  useSaveDraftStageIctIctMutation,
  useStageIctIctMutation,
} = apiStageIct;

export default apiStageIct;
