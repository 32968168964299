import RtkQueryService from "./RtkQueryService";

interface GetVehicleMessageRequest {}

interface GetVehicleMessageResponse {
  data: {
    id: string;
    message: string;
    user: {
      id: string;
      name: string;
    } | null;
    createdBy: "SYSTEM" | "USER";
    createdAt: string;
  }[];
}

interface StoreVehicleMessageRequest {
  message: string;
}

interface StoreVehicleMessageResponse {
  message: string;
}

const RTK_VEHICLE_MESSAGE_TAG = "VehicleMessage" as const;

const apiVehicleMessage = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_VEHICLE_MESSAGE_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getVehicleMessage: build.query<
      GetVehicleMessageResponse,
      {
        vehicleId: string;
        params: GetVehicleMessageRequest;
      }
    >({
      query: ({ vehicleId, params }) => ({
        url: `/vehicles/${vehicleId}/messages`,
        method: "GET",
        params: params,
      }),
      providesTags: (result, _, { vehicleId }) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_VEHICLE_MESSAGE_TAG,
                vehicleId: vehicleId,
                id: id,
              })),
              {
                type: RTK_VEHICLE_MESSAGE_TAG,
                vehicleId: vehicleId,
                id: "LIST",
              },
            ]
          : [
              {
                type: RTK_VEHICLE_MESSAGE_TAG,
                vehicleId: vehicleId,
                id: "LIST",
              },
            ],
    }),
    storeVehicleMessage: build.mutation<
      StoreVehicleMessageResponse,
      {
        vehicleId: string;
        data: StoreVehicleMessageRequest;
      }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/vehicles/${vehicleId}/messages`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              {
                type: RTK_VEHICLE_MESSAGE_TAG,
                vehicleId: vehicleId,
                id: "LIST",
              },
            ]
          : [],
    }),
  }),
});

export const { useGetVehicleMessageQuery, useStoreVehicleMessageMutation } =
  apiVehicleMessage;

export default apiVehicleMessage;
