import MakeLogo from "@/components/shared/MakeLogo";
import VehiclePlate from "@/components/shared/VehiclePlate";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from "@/components/ui/Command";
import { useLazySearchActiveDealerGroupVehiclesQuery } from "@/services/activeDealerGroup/vehicleService";
import { Loader2Icon } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { formatUKVehicleRegistration } from "@/utils/string";

// TODO: show dealer name on the search result
const SearchVehicle = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [searchVehicle, result] = useLazySearchActiveDealerGroupVehiclesQuery();

  const onOpen = () => {
    setOpen(true);
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);

    if (!open) {
      setSearch("");
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchDebounce = useCallback(
    debounce((value: string) => {
      searchVehicle({ registration: value });
    }, 500),
    [],
  );

  const onSearch = async (value: string) => {
    setSearch(value);
    onSearchDebounce(value);
  };

  const groupedVehicles = useMemo(
    () =>
      result.data?.data.reduce(
        (acc, vehicle) => {
          if (!acc[vehicle.dealerName]) {
            acc[vehicle.dealerName] = [];
          }

          acc[vehicle.dealerName].push(vehicle);

          return acc;
        },
        {} as Record<string, typeof result.data.data>,
      ) ?? {},
    [result],
  );

  return (
    <>
      <VehiclePlate role="button" onClick={onOpen} />

      <CommandDialog open={open} onOpenChange={onOpenChange}>
        <CommandInput
          placeholder="Search vehicle by registration..."
          value={search}
          onValueChange={(value) => onSearch(value)}
        />
        <CommandList className="relative">
          {result.isFetching ? (
            <CommandLoading>
              <Loader2Icon className="h-6 w-6 animate-spin" />
            </CommandLoading>
          ) : (
            <CommandEmpty>No results found.</CommandEmpty>
          )}
          {search !== "" &&
            Object.entries(groupedVehicles).map(([dealerName, vehicles]) => (
              <CommandGroup key={dealerName} heading={dealerName}>
                {vehicles.map((vehicle) => (
                  <CommandItem
                    value={vehicle.registration ?? vehicle.shortChassis}
                    className="flex items-center space-x-2"
                    onSelect={() => {
                      // TODO: handle this page, it should be allowed when the vehicle is in the same dealer group
                      navigate(`/vehicles/${vehicle.id}`);
                      onOpenChange(false);
                    }}
                  >
                    <VehiclePlate.TableVersion className="flex-none">
                      {vehicle.registration
                        ? formatUKVehicleRegistration(vehicle.registration)
                        : vehicle.shortChassis}
                    </VehiclePlate.TableVersion>

                    {vehicle.makeName ? (
                      <div className="flex grow items-center space-x-1">
                        {vehicle.makeLogoUrl && (
                          <MakeLogo
                            src={vehicle.makeLogoUrl}
                            alt={vehicle.makeLogoUrl}
                            className="h-5 !w-full"
                          />
                        )}
                        <span>
                          {vehicle.makeName} -{" "}
                          {vehicle.modelName ?? vehicle.modelCustom}
                        </span>
                      </div>
                    ) : (
                      <div className="grow">
                        <span className="italic">&lt;Unknown Make&gt;</span>
                      </div>
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}{" "}
        </CommandList>
      </CommandDialog>
    </>
  );
};

export default SearchVehicle;
