import { createContext, useContext } from "react";

export type Theme = "dark" | "light" | "system";
export type ActiveTheme = "dark" | "light";

interface ThemeContextValue {
  theme: Theme;
  activeTheme: ActiveTheme;
  onChangeTheme: (newTheme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextValue>({} as ThemeContextValue);

// eslint-disable-next-line react-refresh/only-export-components
export const useTheme = () => useContext(ThemeContext);

export default ThemeContext;
