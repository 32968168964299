import { DealerRole, UserRole } from "@/types/role";
import { AuthorizationRule } from "@/utils/authorize";

export const rolePermissions = {
  PROFILE: {
    EDIT_PHOTO: rule([UserRole.DEVELOPER]),
    EDIT_NAME: rule([UserRole.DEVELOPER]),
    EDIT_USERNAME: rule([UserRole.DEVELOPER]),
    EDIT_EMAIL: rule([
      UserRole.DEVELOPER,
      UserRole.SUPER_ADMIN,
      UserRole.EMPLOYEE,
    ]),
  },
  MASTER: {
    MAKE: {
      READ: rule([UserRole.DEVELOPER]),
      ADD: rule([UserRole.DEVELOPER]),
      EDIT: rule([UserRole.DEVELOPER]),
      DELETE: rule([UserRole.DEVELOPER]),
    },
    MODEL: {
      READ: rule([UserRole.DEVELOPER]),
      ADD: rule([UserRole.DEVELOPER]),
      EDIT: rule([UserRole.DEVELOPER]),
      DELETE: rule([UserRole.DEVELOPER]),
    },
    DEALER_GROUP: {
      READ: rule([UserRole.DEVELOPER]),
      ADD: rule([UserRole.DEVELOPER]),
      EDIT: rule([UserRole.DEVELOPER]),
      DELETE: rule([UserRole.DEVELOPER]),
    },
    DEALER: {
      READ: rule([UserRole.DEVELOPER]),
      ADD: rule([UserRole.DEVELOPER]),
      EDIT: rule([UserRole.DEVELOPER]),
      DELETE: rule([UserRole.DEVELOPER]),
      ADMIN: {
        READ: rule([UserRole.DEVELOPER]),
        ASSIGN: rule([UserRole.DEVELOPER]),
        UNASSIGN: rule([UserRole.DEVELOPER]),
      },
      EMPLOYEE: {
        READ: rule([UserRole.DEVELOPER]),
      },
    },
    USER: {
      READ: rule([UserRole.DEVELOPER]),
      ADD: rule([UserRole.DEVELOPER]),
      EDIT: rule([UserRole.DEVELOPER]),
      EDIT_PASSWORD: rule([UserRole.DEVELOPER]),
      DELETE: rule([UserRole.DEVELOPER]),
    },
    FILE_CONFIG: {
      READ: rule([UserRole.DEVELOPER]),
      ADD: rule([UserRole.DEVELOPER]),
      EDIT: rule([UserRole.DEVELOPER]),
      DELETE: rule([UserRole.DEVELOPER]),
    },
  },
  QUEUE_JOB: {
    READ: rule([UserRole.DEVELOPER]),
  },
  ACTIVE_DEALER: {
    MAKE: {
      READ: rule([UserRole.SUPER_ADMIN]),
      ACTIVATE: rule([UserRole.SUPER_ADMIN]),
      DEACTIVATE: rule([UserRole.SUPER_ADMIN]),
      UPDATE_FEATURED: rule([UserRole.SUPER_ADMIN]),
    },
    EMPLOYEE: {
      READ: rule([UserRole.SUPER_ADMIN]),
      ADD: rule([UserRole.SUPER_ADMIN]),
      EDIT: rule([UserRole.SUPER_ADMIN]),
      DELETE: rule([UserRole.SUPER_ADMIN]),
    },
    VEHICLE: {
      READ: rule([UserRole.EMPLOYEE]),
      MESSAGE: {
        ADD: rule([UserRole.EMPLOYEE]),
      },
      CRM_CONFIRMATION: {
        READ: rule([[UserRole.EMPLOYEE, [DealerRole.ADMIN]]]),
        CONFIRM: rule([[UserRole.EMPLOYEE, [DealerRole.ADMIN]]]),
      },
      AGENCY_STOCK: {
        READ: rule([UserRole.EMPLOYEE]),
      },
      STAGE: {
        INFLEET: {
          INFLEET: rule([UserRole.EMPLOYEE]),
        },
        ADMIN: {
          READ: rule([[UserRole.EMPLOYEE, [DealerRole.ADMIN]]]),
          CONFIGURE: rule([[UserRole.EMPLOYEE, [DealerRole.ADMIN]]]),
        },
        ICT: {
          READ: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.SALES_EXECUTIVE, DealerRole.SALES_MANAGER],
            ],
          ]),
          UPDATE_CHECKBOX_VIEWED_SOLD: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.SALES_EXECUTIVE, DealerRole.SALES_MANAGER],
            ],
          ]),
          UPDATE_CHECKBOX_ICT: rule([
            [UserRole.EMPLOYEE, [DealerRole.SALES_MANAGER]],
          ]),
          ICT: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.SALES_EXECUTIVE, DealerRole.SALES_MANAGER],
            ],
          ]),
        },
        MECHANICAL_PREP: {
          READ: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.MECHANICAL_PREP, DealerRole.SALES_MANAGER],
            ],
          ]),
          ASSESS: rule([[UserRole.EMPLOYEE, [DealerRole.MECHANICAL_PREP]]]),
          QUOTE: rule([[UserRole.EMPLOYEE, [DealerRole.MECHANICAL_PREP]]]),
          APPROVAL: rule([[UserRole.EMPLOYEE, [DealerRole.SALES_MANAGER]]]),
          COMPLETION: rule([[UserRole.EMPLOYEE, [DealerRole.MECHANICAL_PREP]]]),
        },
        BODY_DAMAGE: {
          READ: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.BODY_DAMAGE, DealerRole.SALES_MANAGER],
            ],
          ]),
          ASSESS: rule([[UserRole.EMPLOYEE, [DealerRole.BODY_DAMAGE]]]),
          QUOTE: rule([[UserRole.EMPLOYEE, [DealerRole.BODY_DAMAGE]]]),
          APPROVAL: rule([[UserRole.EMPLOYEE, [DealerRole.SALES_MANAGER]]]),
          COMPLETION: rule([[UserRole.EMPLOYEE, [DealerRole.BODY_DAMAGE]]]),
        },
        MEDIA: {
          READ: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.SALES_SUPPORT, DealerRole.SALES_EXECUTIVE],
            ],
          ]),
          VALET: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.SALES_SUPPORT, DealerRole.SALES_EXECUTIVE],
            ],
          ]),
          MEDIA: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.SALES_SUPPORT, DealerRole.SALES_EXECUTIVE],
            ],
          ]),
        },
        SALE: {
          READ: rule([
            [
              UserRole.EMPLOYEE,
              [
                DealerRole.ADMIN,
                DealerRole.SALES_SUPPORT,
                DealerRole.SALES_EXECUTIVE,
                DealerRole.SALES,
                DealerRole.SALES_MANAGER,
              ],
            ],
          ]),
          POST: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.SALES_SUPPORT, DealerRole.SALES_EXECUTIVE],
            ],
          ]),
          SOLD: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.ADMIN, DealerRole.SALES, DealerRole.SALES_MANAGER],
            ],
          ]),
          SOLD_DIRECTLY: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.ADMIN, DealerRole.SALES, DealerRole.SALES_MANAGER],
            ],
          ]),
        },
        OFFSITE: {
          OFFSITE: rule([[UserRole.EMPLOYEE, [DealerRole.SALES]]]),
          ONSITE: rule([[UserRole.EMPLOYEE, [DealerRole.SALES]]]),
        },
      },
    },
  },
  ACTIVE_DEALER_GROUP: {
    VEHICLE: {
      READ_LIST: rule([UserRole.EMPLOYEE]),
      READ: rule([UserRole.EMPLOYEE]),
      STAGE: {
        SALE: {
          SOLD: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.ADMIN, DealerRole.SALES, DealerRole.SALES_MANAGER],
            ],
          ]),
          SOLD_DIRECTLY: rule([
            [
              UserRole.EMPLOYEE,
              [DealerRole.ADMIN, DealerRole.SALES, DealerRole.SALES_MANAGER],
            ],
          ]),
        },
      },
    },
  },
  SITE_SETTINGS: {
    UK_VEHICLE_DATA_API_KEY: {
      READ: rule([UserRole.DEVELOPER]),
      CHANGE: rule([UserRole.DEVELOPER]),
    },
  },
} as const;

function rule(rules: AuthorizationRule[]): AuthorizationRule[] {
  return rules;
}
