import apiActiveDealers from "@/services/ActiveDealerService";
import apiAuth from "@/services/AuthService";
import apiDealerGroups from "@/services/DealerGroupsService";
import apiDealerMakes from "@/services/DealerMakesService";
import apiDealers from "@/services/DealersService";
import apiFileUploadsConfig from "@/services/FileUploadConfigsService";
import apiMakes from "@/services/MakesService";
import apiQueueJobs from "@/services/QueueJobService";
import apiRole from "@/services/RoleService";
import apiSaleType from "@/services/SaleTypeService";
import apiSource from "@/services/SourceService";
import apiStageAdmin from "@/services/stage/adminService";
import apiStageIct from "@/services/stage/IctService";
import apiStageBodyDamage from "@/services/stage/bodyDamageService";
import apiInfleet from "@/services/stage/InfleetService";
import apiStageMechanicalPrep from "@/services/stage/mechanicalPrepService";
import apiStageMedia from "@/services/stage/mediaService";
import apiStageOffsite from "@/services/stage/offsiteService";
import apiStageSale from "@/services/stage/saleService";
import apiStageService from "@/services/stage/stageService";
import apiActiveUserDevice from "@/services/ActiveUserDeviceService";
import apiUser from "@/services/UserService";
import apiUsers from "@/services/UsersService";
import { useAppDispatch } from "@/store";
import { useCallback } from "react";
import apiReport from "@/services/ReportService";
import apiActiveDealerGroupVehicles from "@/services/activeDealerGroup/vehicleService";
import apiActiveDealerGroupDealer from "@/services/activeDealerGroup/dealerService";
import apiVehicle from "@/services/VehicleService";
import apiVehicleMessage from "@/services/VehicleMessageService";
import apiVehicleCrmConfirmation from "@/services/VehicleCrmConfirmationService";
import apiSiteSettings from "@/services/SiteSettingsService";

export default function useClearRtk() {
  const dispatch = useAppDispatch();

  const callback = useCallback(() => {
    dispatch(apiActiveDealers.util.resetApiState());
    dispatch(apiActiveDealerGroupVehicles.util.resetApiState());
    dispatch(apiActiveDealerGroupDealer.util.resetApiState());
    dispatch(apiAuth.util.resetApiState());
    dispatch(apiDealerGroups.util.resetApiState());
    dispatch(apiDealerMakes.util.resetApiState());
    dispatch(apiDealers.util.resetApiState());
    dispatch(apiFileUploadsConfig.util.resetApiState());
    dispatch(apiMakes.util.resetApiState());
    dispatch(apiRole.util.resetApiState());
    dispatch(apiSaleType.util.resetApiState());
    dispatch(apiSource.util.resetApiState());
    dispatch(apiUser.util.resetApiState());
    dispatch(apiUsers.util.resetApiState());
    dispatch(apiQueueJobs.util.resetApiState());
    dispatch(apiActiveUserDevice.util.resetApiState());
    dispatch(apiReport.util.resetApiState());
    //
    dispatch(apiVehicle.util.resetApiState());
    dispatch(apiVehicleMessage.util.resetApiState());
    dispatch(apiVehicleCrmConfirmation.util.resetApiState());
    dispatch(apiStageService.util.resetApiState());
    dispatch(apiInfleet.util.resetApiState());
    dispatch(apiStageIct.util.resetApiState());
    dispatch(apiStageAdmin.util.resetApiState());
    dispatch(apiStageMechanicalPrep.util.resetApiState());
    dispatch(apiStageBodyDamage.util.resetApiState());
    dispatch(apiStageMedia.util.resetApiState());
    dispatch(apiStageSale.util.resetApiState());
    dispatch(apiStageOffsite.util.resetApiState());
    //
    dispatch(apiSiteSettings.util.resetApiState());
  }, [dispatch]);

  return callback;
}
