import RtkQueryService from "./RtkQueryService";

interface ActiveDealerEmployee {
  userId: string;
  name: string;
  username: string;
  email: string;
  profilePhotoUrl: string;
}

interface GetActiveDealerEmployeesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  roles: string[];
}

export interface GetActiveDealerEmployeesResponse {
  data: {
    data: (ActiveDealerEmployee & {
      roles: string[];
    })[];
    totalCount: number;
    filteredCount: number;
  };
}

export interface FindActiveDealerEmployeesResponse {
  data: ActiveDealerEmployee & {
    roles: {
      id: string;
      name: string;
    }[];
  };
}

interface CheckActiveDealerEmployeeRequest {
  type: string; // "email" | "username"
  search: string;
}

interface CheckActiveDealerEmployeeResponse {
  data: {
    id: string;
    name: string;
    username: string;
    email: string;
    profilePhotoUrl: string;
  };
}

interface AddActiveDealerEmployeeRequest {
  data: {
    userId: string;
    roleIds: string[];
  };
}

interface AddActiveDealerEmployeeResponse {
  message: string;
}

interface CreateActiveDealerEmployeeRequest {
  data: {
    name: string;
    username: string;
    email: string;
    password: string;
    profilePhotoFileId: string | null;
  };
}

interface CreateActiveDealerEmployeeResponse {
  message: string;
  data: {
    userId: string;
  };
}

interface SyncRoleActiveDealerEmployeeRequest {
  roleIds: string[];
}

interface SyncRoleActiveDealerEmployeeResponse {
  message: string;
}

interface RemoveActiveDealerEmployeeResponsse {
  message: string;
}

export const RTK_ACTIVE_DEALER_EMPLOYEE_TAG = "ActiveDealerEmployee" as const;

const apiActiveDealers = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_ACTIVE_DEALER_EMPLOYEE_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getActiveDealerEmployees: build.query<
      GetActiveDealerEmployeesResponse,
      GetActiveDealerEmployeesRequest
    >({
      query: (params) => ({
        url: `/active-dealer/employees`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ userId }) => ({
                type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG,
                id: userId,
              })),
              { type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: "LIST" },
            ]
          : [{ type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: "LIST" }],
    }),
    findActiveDealerEmployees: build.query<
      FindActiveDealerEmployeesResponse,
      string
    >({
      query: (userId) => ({
        url: `/active-dealer/employees/${userId}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [{ type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: result.data.userId }]
          : [],
    }),
    checkActiveDealerUnassignedUser: build.query<
      CheckActiveDealerEmployeeResponse,
      CheckActiveDealerEmployeeRequest
    >({
      query: (params) => ({
        url: `/active-dealer/employees/check`,
        method: "GET",
        params,
      }),
    }),
    addActiveDealerEmployee: build.mutation<
      AddActiveDealerEmployeeResponse,
      AddActiveDealerEmployeeRequest
    >({
      query: ({ data }) => ({
        url: `/active-dealer/employees/add`,
        method: "POST",
        data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: "LIST" }] : [],
    }),
    createActiveDealerEmployee: build.mutation<
      CreateActiveDealerEmployeeResponse,
      CreateActiveDealerEmployeeRequest
    >({
      query: ({ data }) => ({
        url: `/active-dealer/employees`,
        method: "POST",
        data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: "LIST" }] : [],
    }),
    syncRoleActiveDealerEmployee: build.mutation<
      SyncRoleActiveDealerEmployeeResponse,
      { userId: string; data: SyncRoleActiveDealerEmployeeRequest }
    >({
      query: ({ userId, data }) => ({
        url: `/active-dealer/employees/${userId}/sync-role`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { userId }) =>
        result
          ? [
              { type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: "LIST" },
              { type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: userId },
            ]
          : [],
    }),
    removeEmployeeFromActiveDealer: build.mutation<
      RemoveActiveDealerEmployeeResponsse,
      string
    >({
      query: (userId) => ({
        url: `/active-dealer/employees/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, userId) =>
        result
          ? [
              { type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: "LIST" },
              { type: RTK_ACTIVE_DEALER_EMPLOYEE_TAG, id: userId },
            ]
          : [],
    }),
  }),
});

export const {
  useGetActiveDealerEmployeesQuery,
  useFindActiveDealerEmployeesQuery,
  useLazyCheckActiveDealerUnassignedUserQuery,
  useAddActiveDealerEmployeeMutation,
  useCreateActiveDealerEmployeeMutation,
  useSyncRoleActiveDealerEmployeeMutation,
  useRemoveEmployeeFromActiveDealerMutation,
} = apiActiveDealers;

export default apiActiveDealers;
