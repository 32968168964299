import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import store, { persistor } from "./store";
import App from "./App.tsx";
import { isSupported } from "firebase/messaging";
import * as Sentry from "@sentry/react";

import "./globals.css";
import "./assets/fonts/charlesWright/style.css";
import { Loader2Icon } from "lucide-react";
import { ThemeProvider } from "./components/context/ThemeContext";
import { SocketIoProvider } from "./contexts/socketIo/socketIo.provider.tsx";

// eslint-disable-next-line react-refresh/only-export-components
const FirebaseCloudMessaging = lazy(
  () => import("./components/FirebaseCloudMessaging"),
);

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_APP_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

(async () => {
  const supported = await isSupported();

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <SocketIoProvider>
            <ThemeProvider>
              <BrowserRouter>
                {supported ? (
                  <Suspense
                    fallback={
                      <div className="flex h-screen items-center justify-center">
                        <Loader2Icon className="h-8 w-8 animate-spin" />
                      </div>
                    }
                  >
                    <FirebaseCloudMessaging>
                      <App />
                    </FirebaseCloudMessaging>
                  </Suspense>
                ) : (
                  <App />
                )}
              </BrowserRouter>
            </ThemeProvider>
          </SocketIoProvider>
        </PersistGate>
      </ReduxProvider>
    </React.StrictMode>,
  );
})();
