import { workflowStageStatus } from "@/constants/vehicleStage.constant";

export const trimString = (u: unknown) =>
  typeof u === "string" ? u.trim() : u;

export const getTwoLetterAlias = (name: string): string => {
  const words = name.split(" ");
  let alias = "";

  if (words.length >= 2) {
    alias = words[0][0] + words[1][0];
  } else if (words.length === 1) {
    alias = words[0].slice(0, 2);
  }

  return alias.toUpperCase();
};

export const convertStageCodeToNatural = (code: string): string => {
  switch (code) {
    case workflowStageStatus.INFLEET._:
      return "Infleet";
    case workflowStageStatus.ADMIN._:
      return "Admin";
    case workflowStageStatus.ICT._:
      return "ICT";
    case workflowStageStatus.MECHANICAL_PREP._:
      return "Mechanical Prep";
    case workflowStageStatus.BODY_DAMAGE._:
      return "Body Damage";
    case workflowStageStatus.MEDIA._:
      return "Media";
    case workflowStageStatus.SALE._:
      return "Sale";
    case workflowStageStatus.OFFSITE._:
      return "Offsite";
    case workflowStageStatus.CLOSED._:
      return "Closed";
    default:
      return "-";
  }
};

export const convertStageStatusToNatural = (
  stageCode: string,
  code: string,
) => {
  if (stageCode === workflowStageStatus.INFLEET._) {
    if (code === workflowStageStatus.INFLEET.ENTRY_DATA) {
      return "Entry Data";
    }
  } else if (stageCode === workflowStageStatus.ADMIN._) {
    if (code === workflowStageStatus.ADMIN.CONFIGURE) {
      return "Configure";
    }
  } else if (stageCode === workflowStageStatus.ICT._) {
    if (code === workflowStageStatus.ICT.ICT) {
      return "ICT";
    }
  } else if (stageCode === workflowStageStatus.MECHANICAL_PREP._) {
    if (code === workflowStageStatus.MECHANICAL_PREP.ASSESS) {
      return "Assess";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.QUOTE) {
      return "Quote";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.QUOTE_REVISION) {
      return "Quote Revision";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.APPROVAL) {
      return "Approval";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.IN_WORKSHOP) {
      return "In Workshop";
    }
  } else if (stageCode === workflowStageStatus.BODY_DAMAGE._) {
    if (code === workflowStageStatus.BODY_DAMAGE.ASSESS) {
      return "Assess";
    } else if (code === workflowStageStatus.BODY_DAMAGE.QUOTE) {
      return "Quote";
    } else if (code === workflowStageStatus.BODY_DAMAGE.QUOTE_REVISION) {
      return "Quote Revision";
    } else if (code === workflowStageStatus.BODY_DAMAGE.APPROVAL) {
      return "Approval";
    } else if (code === workflowStageStatus.BODY_DAMAGE.REPAIR) {
      return "Repair";
    }
  } else if (stageCode === workflowStageStatus.MEDIA._) {
    if (code === workflowStageStatus.MEDIA.VALET) {
      return "Valet";
    } else if (code === workflowStageStatus.MEDIA.MEDIA) {
      return "Media";
    }
  } else if (stageCode === workflowStageStatus.SALE._) {
    if (code === workflowStageStatus.SALE.POST) {
      return "Post";
    } else if (code === workflowStageStatus.SALE.ON_SALE) {
      return "On Sale";
    }
  } else if (stageCode === workflowStageStatus.CLOSED._) {
    if (code === workflowStageStatus.CLOSED.SOLD_DIRECTLY) {
      return "Sold Directly";
    } else if (code === workflowStageStatus.CLOSED.SOLD) {
      return "Sold";
    }
  } else if (stageCode === workflowStageStatus.OFFSITE._) {
    if (code === workflowStageStatus.OFFSITE.OFFSITE) {
      return "Offsite";
    }
  }

  return "-";
};

export const convertStageStatusToFullInfo = (
  stageCode: string,
  code: string,
) => {
  if (stageCode === workflowStageStatus.INFLEET._) {
    if (code === workflowStageStatus.INFLEET.ENTRY_DATA) {
      return "Infleet: Entry Data";
    }
  } else if (stageCode === workflowStageStatus.ADMIN._) {
    if (code === workflowStageStatus.ADMIN.CONFIGURE) {
      return "Admin: Configure";
    }
  } else if (stageCode === workflowStageStatus.ICT._) {
    if (code === workflowStageStatus.ICT.ICT) {
      return "ICT";
    }
  } else if (stageCode === workflowStageStatus.MECHANICAL_PREP._) {
    if (code === workflowStageStatus.MECHANICAL_PREP.ASSESS) {
      return "Mechanical Prep: Assess";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.QUOTE) {
      return "Mechanical Prep: Quote";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.QUOTE_REVISION) {
      return "Mechanical Prep: Quote Revision";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.APPROVAL) {
      return "Mechanical Prep: Approval";
    } else if (code === workflowStageStatus.MECHANICAL_PREP.IN_WORKSHOP) {
      return "Mechanical Prep: In Workshop";
    }
  } else if (stageCode === workflowStageStatus.BODY_DAMAGE._) {
    if (code === workflowStageStatus.BODY_DAMAGE.ASSESS) {
      return "Body Damage: Assess";
    } else if (code === workflowStageStatus.BODY_DAMAGE.QUOTE) {
      return "Body Damage: Quote";
    } else if (code === workflowStageStatus.BODY_DAMAGE.QUOTE_REVISION) {
      return "Body Damage: Quote Revision";
    } else if (code === workflowStageStatus.BODY_DAMAGE.APPROVAL) {
      return "Body Damage: Approval";
    } else if (code === workflowStageStatus.BODY_DAMAGE.REPAIR) {
      return "Body Damage: Repair";
    }
  } else if (stageCode === workflowStageStatus.MEDIA._) {
    if (code === workflowStageStatus.MEDIA.VALET) {
      return "Media: Valet";
    } else if (code === workflowStageStatus.MEDIA.MEDIA) {
      return "Media: Media";
    }
  } else if (stageCode === workflowStageStatus.SALE._) {
    if (code === workflowStageStatus.SALE.POST) {
      return "Sale: Post";
    } else if (code === workflowStageStatus.SALE.ON_SALE) {
      return "Sale: On Sale";
    }
  } else if (stageCode === workflowStageStatus.CLOSED._) {
    if (code === workflowStageStatus.CLOSED.SOLD_DIRECTLY) {
      return "Closed: Sold Directly";
    } else if (code === workflowStageStatus.CLOSED.SOLD) {
      return "Closed: Sold";
    }
  } else if (stageCode === workflowStageStatus.OFFSITE._) {
    if (code === workflowStageStatus.OFFSITE.OFFSITE) {
      return "Offsite";
    }
  }

  return "-";
};

export const formatNumberWithCommas = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Formats a UK vehicle registration number by adding a space after the last number.
 *
 * @param registration - The vehicle registration number to format.
 * @returns The formatted vehicle registration number.
 */
export const formatUKVehicleRegistration = (registration: string) => {
  const match = registration.match(/^(\D*\d+)(.*)$/);
  if (match) {
    return match[1] + " " + match[2];
  }
  return registration;
};
