import dayjs from "dayjs";
import RtkQueryService from "./RtkQueryService";
import socketIoService from "@/contexts/socketIo/socketIo";
import store from "@/store";

interface GetReportRequest {
  date: string;
}

interface GetReportResponse {
  data: {
    stage: string;
    activeCount: number;
    incomingCount: number;
    outgoingCount: number;
    statuses: {
      status: string;
      count: number;
    }[];
  }[];
}

interface GetReportAgencyStockCountRequest {
  date: string;
}

interface GetReportAgencyStockCountResponse {
  data: {
    activeCount: number;
    incomingCount: number;
    outgoingCount: number;
  };
}

interface GetReportMechanicalPrepPreppedRequest {
  date: string;
}

interface GetReportMechanicalPrepPreppedResponse {
  data: {
    preppedCount: number;
    unpreppedCount: number;
    incomingUnpreppedCount: number;
    incomingPreppedCount: number;
  };
}

export const RTK_REPORT_TAG = "Report" as const;

const apiReport = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_REPORT_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getReportStages: build.query<GetReportResponse, GetReportRequest>({
      query: (params) => ({
        url: `/report/stages`,
        method: "GET",
        params,
      }),
      providesTags: (result, _, params) => {
        if (!result) return [];

        const today = dayjs.utc().add(-1, "hour").format("YYYY-MM-DD");

        return today === params.date
          ? [{ type: RTK_REPORT_TAG, id: "TODAY" }]
          : [{ type: RTK_REPORT_TAG, id: params.date }];
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        const today = dayjs.utc().add(1, "hour").format("YYYY-MM-DD");
        const isToday = arg.date === today;

        if (!isToday) return;

        const dealerId = store.getState().auth.session.activeDealerId!;

        const socketHandler = socketIoService.onTokenChange((socket) => {
          const callback = (
            _dealerId: string,
            data: GetReportResponse["data"],
          ) => {
            updateCachedData((draft) => {
              draft.data = data;
            });
          };

          socket.emit("dealer report stages today join", dealerId);
          socket.on("dealer report stages today", callback);

          return () => {
            socket.emit("dealer report stages today leave", dealerId);
            socket.off("dealer report stages today", callback);
          };
        });

        try {
          await cacheDataLoaded;
          socketHandler.startListening();
        } catch {
          //
        }

        await cacheEntryRemoved;
        socketHandler.stopListening();
      },
    }),
    getReportAgencyStockCount: build.query<
      GetReportAgencyStockCountResponse,
      GetReportAgencyStockCountRequest
    >({
      query: (params) => ({
        url: `/report/agency-stock/count`,
        method: "GET",
        params,
      }),
      providesTags: (result, _, params) => {
        if (!result) return [];

        const today = dayjs.utc().add(-1, "hour").format("YYYY-MM-DD");

        return today === params.date
          ? [{ type: RTK_REPORT_TAG, id: "TODAY" }]
          : [{ type: RTK_REPORT_TAG, id: params.date }];
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        const today = dayjs.utc().add(1, "hour").format("YYYY-MM-DD");
        const isToday = arg.date === today;

        if (!isToday) return;

        const dealerId = store.getState().auth.session.activeDealerId!;

        const socketHandler = socketIoService.onTokenChange((socket) => {
          const callback = (
            _dealerId: string,
            data: GetReportAgencyStockCountResponse["data"],
          ) => {
            updateCachedData((draft) => {
              draft.data = data;
            });
          };

          socket.emit("dealer report agencyStockCount today join", dealerId);
          socket.on("dealer report agencyStockCount today", callback);

          return () => {
            socket.emit("dealer report agencyStockCount today leave", dealerId);
            socket.off("dealer report agencyStockCount today", callback);
          };
        });

        try {
          await cacheDataLoaded;
          socketHandler.startListening();
        } catch {
          //
        }

        await cacheEntryRemoved;
        socketHandler.stopListening();
      },
    }),
    getReportMechanicalPrepPreppedCount: build.query<
      GetReportMechanicalPrepPreppedResponse,
      GetReportMechanicalPrepPreppedRequest
    >({
      query: (params) => ({
        url: `/report/mechanical-prep/prepped`,
        method: "GET",
        params,
      }),
      providesTags: (result, _, params) => {
        if (!result) return [];

        const today = dayjs.utc().add(-1, "hour").format("YYYY-MM-DD");

        return today === params.date
          ? [{ type: RTK_REPORT_TAG, id: "TODAY" }]
          : [{ type: RTK_REPORT_TAG, id: params.date }];
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        const today = dayjs.utc().add(1, "hour").format("YYYY-MM-DD");
        const isToday = arg.date === today;

        if (!isToday) return;

        const dealerId = store.getState().auth.session.activeDealerId!;

        const socketHandler = socketIoService.onTokenChange((socket) => {
          const callback = (
            _dealerId: string,
            data: GetReportMechanicalPrepPreppedResponse["data"],
          ) => {
            updateCachedData((draft) => {
              draft.data = data;
            });
          };

          socket.emit("dealer report mechPrepPrepped today join", dealerId);
          socket.on("dealer report mechPrepPrepped today", callback);

          return () => {
            socket.emit("dealer report mechPrepPrepped today leave", dealerId);
            socket.off("dealer report mechPrepPrepped today", callback);
          };
        });

        try {
          await cacheDataLoaded;
          socketHandler.startListening();
        } catch {
          //
        }

        await cacheEntryRemoved;
        socketHandler.stopListening();
      },
    }),
  }),
});

export const {
  useGetReportStagesQuery,
  useGetReportAgencyStockCountQuery,
  useGetReportMechanicalPrepPreppedCountQuery,
} = apiReport;

export default apiReport;
