import RtkQueryService from "./RtkQueryService";

interface Make {
  id: string;
  name: string;
  logoUrl: string | null;
  createdAt: string;
}

interface MakeModel {
  id: string;
  name: string;
  createdAt: string;
}

interface GetMakesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
}

export interface GetMakesResponse {
  data: {
    data: Make[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindMakeResponse {
  data: Make & {
    logo: {
      id: string;
      name: string;
      filename: string;
      size: number;
      mimeType: string;
    } | null;
  };
}

interface StoreMakeRequest {
  name: string;
  logoFileId: string | null;
}

interface StoreMakeResponse {
  message: string;
}

interface UpdateMakeRequest {
  id: string;
  data: {
    name: string;
    logoFileId?: string | null; // string = add/replace, null = remove, undefined = no changes
  };
}

interface UpdateMakeResponse {
  message: string;
}

interface GetMakeModelsRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
}

export interface GetMakeModelsResponse {
  data: {
    data: MakeModel[];
    totalCount: number;
    filteredCount: number;
  };
}

export interface FindMakeModelResponse {
  data: MakeModel;
}

interface StoreMakeModelRequest {
  name: string;
}

interface StoreMakeModelResponse {
  message: string;
}

interface UpdateMakeModelRequest {
  name: string;
}

interface UpdateMakeModelResponse {
  message: string;
}

interface DeleteMakeModelResponse {
  message: string;
}

export const RTK_MAKES_TAG = "Makes" as const;
export const RTK_MAKES_MODELS_TAG = "MakesModels" as const;

const apiMakes = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_MAKES_TAG, RTK_MAKES_MODELS_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getMakes: build.query<GetMakesResponse, GetMakesRequest>({
      query: (params) => ({
        url: `/makes`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_MAKES_TAG,
                id,
              })),
              { type: RTK_MAKES_TAG, id: "LIST" },
            ]
          : [{ type: RTK_MAKES_TAG, id: "LIST" }],
    }),
    findMake: build.query<FindMakeResponse, string>({
      query: (id) => ({
        url: `/makes/${id}`,
        method: "GET",
      }),
      providesTags: (result, _, id) =>
        result ? [{ type: RTK_MAKES_TAG, id }] : [],
    }),
    storeMake: build.mutation<StoreMakeResponse, StoreMakeRequest>({
      query: (data) => ({
        url: `/makes`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RTK_MAKES_TAG, id: "LIST" }] : [],
    }),
    updateMake: build.mutation<UpdateMakeResponse, UpdateMakeRequest>({
      query: ({ id, data }) => ({
        url: `/makes/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: RTK_MAKES_TAG, id }] : [],
    }),
    deleteMake: build.mutation<void, string>({
      query: (id) => ({
        url: `/makes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, id) =>
        result
          ? [
              { type: RTK_MAKES_TAG, id },
              { type: RTK_MAKES_MODELS_TAG, id: `LIST_${id}` },
            ]
          : [],
    }),
    // ---
    getMakeModels: build.query<
      GetMakeModelsResponse,
      { makeId: string; params: GetMakeModelsRequest }
    >({
      query: ({ makeId, params }) => ({
        url: `/makes/${makeId}/models`,
        method: "GET",
        params,
      }),
      providesTags: (result, _, { makeId }) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_MAKES_MODELS_TAG,
                id: `${makeId}_${id}`,
              })),
              { type: RTK_MAKES_MODELS_TAG, id: `LIST_${makeId}` },
            ]
          : [{ type: RTK_MAKES_MODELS_TAG, id: `LIST_${makeId}` }],
    }),
    findMakeModel: build.query<
      FindMakeModelResponse,
      { makeId: string; modelId: string }
    >({
      query: ({ makeId, modelId }) => ({
        url: `/makes/${makeId}/models/${modelId}`,
        method: "GET",
      }),
      providesTags: (result, _, { makeId, modelId }) =>
        result
          ? [{ type: RTK_MAKES_MODELS_TAG, id: `${makeId}_${modelId}` }]
          : [],
    }),
    storeMakeModel: build.mutation<
      StoreMakeModelResponse,
      { makeId: string; data: StoreMakeModelRequest }
    >({
      query: ({ makeId, data }) => ({
        url: `/makes/${makeId}/models`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { makeId }) =>
        result ? [{ type: RTK_MAKES_MODELS_TAG, id: `LIST_${makeId}` }] : [],
    }),
    updateMakeModel: build.mutation<
      UpdateMakeModelResponse,
      { makeId: string; modelId: string; data: UpdateMakeModelRequest }
    >({
      query: ({ makeId, modelId, data }) => ({
        url: `/makes/${makeId}/models/${modelId}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { makeId, modelId }) =>
        result
          ? [
              { type: RTK_MAKES_MODELS_TAG, id: `LIST_${makeId}` },
              { type: RTK_MAKES_MODELS_TAG, id: `${makeId}_${modelId}` },
            ]
          : [],
    }),
    deleteMakeModel: build.mutation<
      DeleteMakeModelResponse,
      { makeId: string; modelId: string }
    >({
      query: (data) => ({
        url: `/makes/${data.makeId}/models/${data.modelId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, { makeId, modelId }) =>
        result
          ? [
              { type: RTK_MAKES_MODELS_TAG, id: `LIST_${makeId}` },
              { type: RTK_MAKES_MODELS_TAG, id: `${makeId}_${modelId}` },
            ]
          : [],
    }),
  }),
});

export const {
  useGetMakesQuery,
  useFindMakeQuery,
  useStoreMakeMutation,
  useUpdateMakeMutation,
  useDeleteMakeMutation,
  // ---
  useGetMakeModelsQuery,
  useFindMakeModelQuery,
  useStoreMakeModelMutation,
  useUpdateMakeModelMutation,
  useDeleteMakeModelMutation,
} = apiMakes;

export default apiMakes;
