import { RTK_VEHICLE_TAG } from "../VehicleService";
import RtkQueryService from "../RtkQueryService";
import { RTK_REPORT_TAG } from "../ReportService";

interface MessageResponse {
  message: string;
}

interface GetStageBodyDamageVehiclesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  source: string[];
  saleType: string[];
  make: string[];
  status: string[];
}

export interface GetStageBodyDamageVehiclesResponse {
  data: {
    data: {
      id: string;
      modelName: string | null;
      modelCustom: string | null;
      registration: string | null;
      sourceName: string;
      saleTypeName: string | null;
      makeName: string | null;
      makeLogoUrl: string | null;
      mileage?: number;
      shortChassis: string;
      stage: string;
      stageStatus: string;
      createdAt: string;
      lastUpdatedAt: string;
      isViewed: boolean;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindStageBodyDamageVehicleResponse {
  data: {
    skip: boolean;
    description: string;
    etaEcd: string | null;
    etaEcdHistory: {
      id: string;
      prevEtaEcd: string | null;
      newEtaEcd: string;
      note: string;
      createdAt: string;
      user: {
        id: string;
        name: string;
      };
    }[];
    files: {
      id: string;
      description: string | null;
      file: {
        name: string;
        url: string;
      };
      user: {
        id: string;
        name: string;
      } | null;
      stage: {
        id: string;
        stage: string;
        stageStatus: string;
      } | null;
      createdAt: string;
    }[];
    media: {
      id: string;
      description: string | null;
      file: {
        name: string;
        url: string;
      };
      user: {
        id: string;
        name: string;
      } | null;
      stage: {
        id: string;
        stage: string;
        stageStatus: string;
      } | null;
      createdAt: string;
    }[];
    note: string;
  };
}

interface AssessStageBodyDamageRequest {
  skip: boolean;
  note: string;
}

interface StoreStageBodyDamageFilesRequest {
  fileIds: string[];
}

interface UpdateStageBodyDamageFileRequest {
  description: string;
}

interface SaveDraftStageBodyDamageQuoteRequest {
  description: string;
  note: string;
}

interface ApproveStageBodyDamageRequest {
  note: string;
}

interface RejectStageBodyDamageRequest {
  note: string;
}

interface CompleteDraftStageBodyDamageRequest {
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

interface CompleteStageBodyDamageRequest {
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

type SubmitStageBodyDamageQuoteRequest = SaveDraftStageBodyDamageQuoteRequest;

export const RTK_STAGE_BODY_DAMAGE_TAG = "StageBodyDamage" as const;

const apiStageBodyDamage = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_STAGE_BODY_DAMAGE_TAG, RTK_VEHICLE_TAG, RTK_REPORT_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getStageBodyDamageVehicles: build.query<
      GetStageBodyDamageVehiclesResponse,
      GetStageBodyDamageVehiclesRequest
    >({
      query: (params) => ({
        url: `/stage/body-damage`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_STAGE_BODY_DAMAGE_TAG,
                id: id,
              })),
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: "LIST" },
            ]
          : [{ type: RTK_STAGE_BODY_DAMAGE_TAG, id: "LIST" }],
    }),
    findStageBodyDamageVehicle: build.query<
      FindStageBodyDamageVehicleResponse,
      string
    >({
      query: (vehicleId) => ({
        url: `/stage/body-damage/${vehicleId}`,
        method: "GET",
      }),
      providesTags: (result, _, vehicleId) =>
        result ? [{ type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId }] : [],
    }),
    // ---
    assessStageBodyDamage: build.mutation<
      MessageResponse,
      { vehicleId: string; data: AssessStageBodyDamageRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/assess`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    // ---
    storeStageBodyDamageFiles: build.mutation<
      MessageResponse,
      {
        vehicleId: string;
        type: "document" | "media";
        data: StoreStageBodyDamageFilesRequest;
      }
    >({
      query: ({ vehicleId, type, data }) => ({
        url: `/stage/body-damage/${vehicleId}/files/${type}`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
            ]
          : [],
    }),
    updateStageBodyDamageFile: build.mutation<
      MessageResponse,
      {
        vehicleId: string;
        fileId: string;
        data: UpdateStageBodyDamageFileRequest;
      }
    >({
      query: ({ vehicleId, fileId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/files/${fileId}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
            ]
          : [],
    }),
    deleteStageBodyDamageFile: build.mutation<
      MessageResponse,
      { vehicleId: string; fileId: string }
    >({
      query: ({ vehicleId, fileId }) => ({
        url: `/stage/body-damage/${vehicleId}/files/${fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
            ]
          : [],
    }),
    // ---
    saveDraftStageBodyDamageQuote: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SaveDraftStageBodyDamageQuoteRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/quote/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
            ]
          : [],
    }),
    submitStageBodyDamageQuote: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SubmitStageBodyDamageQuoteRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/quote`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    // ---
    approveStageBodyDamage: build.mutation<
      MessageResponse,
      { vehicleId: string; data: ApproveStageBodyDamageRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/approve`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    rejectStageBodyDamage: build.mutation<
      MessageResponse,
      { vehicleId: string; data: RejectStageBodyDamageRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/reject`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    //
    completeDraftStageBodyDamage: build.mutation<
      MessageResponse,
      { vehicleId: string; data: CompleteDraftStageBodyDamageRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/completion/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
            ]
          : [],
    }),
    completeStageBodyDamage: build.mutation<
      MessageResponse,
      { vehicleId: string; data: CompleteStageBodyDamageRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/body-damage/${vehicleId}/completion`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
  }),
});

export const {
  useGetStageBodyDamageVehiclesQuery,
  useFindStageBodyDamageVehicleQuery,
  useAssessStageBodyDamageMutation,
  //
  useStoreStageBodyDamageFilesMutation,
  useUpdateStageBodyDamageFileMutation,
  useDeleteStageBodyDamageFileMutation,
  //
  useSaveDraftStageBodyDamageQuoteMutation,
  useSubmitStageBodyDamageQuoteMutation,
  //
  useApproveStageBodyDamageMutation,
  useRejectStageBodyDamageMutation,
  //
  useCompleteDraftStageBodyDamageMutation,
  useCompleteStageBodyDamageMutation,
} = apiStageBodyDamage;

export default apiStageBodyDamage;
