import { lazy } from "react";
import authRoute from "./authRoute";
import { Routes } from "@/@types/route";
import { DealerRole, UserRole } from "@/types/role";
import { rolePermissions } from "../rolePermissions.config";

export const publicRoutes: Routes = [...authRoute];

export const protectedRoutes: Routes = [
  {
    key: "home",
    path: "/",
    component: lazy(() => import("@/pages/HomePage")),
    authority: [UserRole.DEVELOPER, UserRole.SUPER_ADMIN, UserRole.EMPLOYEE],
    meta: {
      title: "Overview",
    },
  },
  {
    key: "file-configs",
    path: "/file-configs",
    component: lazy(() => import("@/pages/FileConfig")),
    authority: rolePermissions.MASTER.FILE_CONFIG.READ,
    meta: {
      title: "File Configs",
    },
  },
  {
    key: "queue-jobs",
    path: "/queue-jobs",
    component: lazy(() => import("@/pages/QueueJobPage")),
    authority: rolePermissions.QUEUE_JOB.READ,
    meta: {
      title: "Queue Jobs",
    },
  },
  {
    key: "dealer-groups",
    path: "/dealer-groups",
    component: lazy(() => import("@/pages/DealerGroupsPage")),
    authority: rolePermissions.MASTER.DEALER_GROUP.READ,
    meta: {
      title: "Dealer Groups",
    },
  },
  {
    key: "dealers",
    path: "/dealers",
    component: lazy(() => import("@/pages/DealersPage")),
    authority: rolePermissions.MASTER.DEALER.READ,
    meta: {
      title: "Dealers",
    },
  },
  {
    key: "dealers.detail",
    path: "/dealers/:dealerId/*",
    component: lazy(() => import("@/pages/DealersDetailPage")),
    authority: rolePermissions.MASTER.DEALER.READ,
    meta: {
      title: "Dealers Detail",
    },
  },
  {
    key: "users",
    path: "/users",
    component: lazy(() => import("@/pages/UsersPage")),
    authority: rolePermissions.MASTER.USER.READ,
    meta: {
      title: "Users",
    },
  },
  {
    key: "makes",
    path: "/makes",
    component: lazy(() => import("@/pages/MakePage")),
    authority: rolePermissions.MASTER.MAKE.READ,
    meta: {
      title: "Makes",
    },
  },
  {
    key: "makes.models",
    path: "/makes/:makeId/models",
    component: lazy(() => import("@/pages/MakeModelPage")),
    authority: rolePermissions.MASTER.MODEL.READ,
    meta: {
      title: "Models",
    },
  },
  {
    key: "makes.detail",
    path: "/makes/:makeId/*",
    component: lazy(() => import("@/pages/MakesDetailPage")),
    authority: rolePermissions.MASTER.MAKE.READ,
    meta: {
      title: "Makes Detail",
    },
  },
  {
    key: "dealer.makes",
    path: "/dealer/makes",
    component: lazy(() => import("@/pages/DealerMakePage")),
    authority: rolePermissions.ACTIVE_DEALER.MAKE.READ,
    meta: {
      title: "Makes",
    },
  },
  {
    key: "dealer.employees",
    path: "/dealer/employees",
    component: lazy(() => import("@/pages/activeDealer/EmployeePage")),
    authority: rolePermissions.ACTIVE_DEALER.EMPLOYEE.READ,
    meta: {
      title: "Employees",
    },
  },
  {
    key: "vehicles",
    path: "/vehicles",
    component: lazy(() => import("@/pages/VehiclePage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.READ,
    meta: {
      title: "Vehicles",
    },
  },
  {
    key: "vehicles.agency-stock",
    path: "/vehicles/agency-stock",
    component: lazy(() => import("@/pages/AgencyStockPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.AGENCY_STOCK.READ,
    meta: {
      title: "Agency Stock",
    },
  },
  {
    key: "vehicles.detail",
    path: "/vehicles/:vehicleId",
    component: lazy(() => import("@/pages/VehicleDetailPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.READ,
    meta: {
      title: "Vehicle Detail",
    },
  },
  {
    key: "vehicles.crm-confirmations",
    path: "/vehicle-crm-confirmations",
    component: lazy(() => import("@/pages/VehicleCrmConfirmation")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.CRM_CONFIRMATION.READ,
    meta: {
      title: "CRM Confirmations",
    },
  },
  {
    key: "vehicles.crm-confirmations.detail",
    path: "/vehicle-crm-confirmations/:vehicleId",
    component: lazy(() => import("@/pages/VehicleCrmConfirmationDetail")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.CRM_CONFIRMATION.CONFIRM,
    meta: {
      title: "CRM Confirmation Detail",
    },
  },
  {
    key: "vehicles",
    path: "/vehicles/:vehicleId",
    component: lazy(() => import("@/pages/VehicleDetailPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.READ,
    meta: {
      title: "Vehicle Detail",
    },
  },
  {
    key: "stages.infleet",
    path: "/stages/infleet",
    component: lazy(() => import("@/pages/stages/InfleetPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.INFLEET.INFLEET,
    meta: {
      title: "Infleet",
    },
  },
  {
    key: "stages.admin",
    path: "/stages/admin",
    component: lazy(() => import("@/pages/stages/AdminPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.ADMIN.READ,
    meta: {
      title: "Admin",
    },
  },
  {
    key: "stages.admin.configure",
    path: "/stages/admin/:vehicleId/configure",
    component: lazy(() => import("@/pages/stages/AdminConfigurePage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.ADMIN.CONFIGURE,
    meta: {
      title: "Admin",
    },
  },
  {
    key: "stages.ict",
    path: "/stages/ict",
    component: lazy(() => import("@/pages/stages/IctPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.ICT.READ,
    meta: {
      title: "ICT",
    },
  },
  {
    key: "stages.ict.ict",
    path: "/stages/ict/:vehicleId",
    component: lazy(() => import("@/pages/stages/IctIctPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.ICT.ICT,
    meta: {
      title: "ICT",
    },
  },
  {
    key: "stages.mechanical-prep",
    path: "/stages/mechanical-prep",
    component: lazy(() => import("@/pages/stages/MechanicalPrepPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MECHANICAL_PREP.READ,
    meta: {
      title: "Mechanical Prep",
    },
  },
  {
    key: "stages.mechanical-prep.assess",
    path: "/stages/mechanical-prep/:vehicleId/assess",
    component: lazy(() => import("@/pages/stages/MechanicalPrepAssessPage")),
    authority:
      rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MECHANICAL_PREP.ASSESS,
    meta: {
      title: "Mechanical Prep Assessment",
    },
  },
  {
    key: "stages.mechanical-prep.quote",
    path: "/stages/mechanical-prep/:vehicleId/quote",
    component: lazy(() => import("@/pages/stages/MechanicalPrepQuotePage")),
    authority:
      rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MECHANICAL_PREP.QUOTE,
    meta: {
      title: "Mechanical Prep Quote",
    },
  },
  {
    key: "stages.mechanical-prep.approval",
    path: "/stages/mechanical-prep/:vehicleId/approval",
    component: lazy(() => import("@/pages/stages/MechanicalPrepApprovalPage")),
    authority:
      rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MECHANICAL_PREP.APPROVAL,
    meta: {
      title: "Mechanical Prep Approval",
    },
  },
  {
    key: "stages.mechanical-prep.completion",
    path: "/stages/mechanical-prep/:vehicleId/completion",
    component: lazy(
      () => import("@/pages/stages/MechanicalPrepCompletionPage"),
    ),
    authority:
      rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MECHANICAL_PREP.COMPLETION,
    meta: {
      title: "Mechanical Prep Completion",
    },
  },
  {
    key: "stages.body-damage",
    path: "/stages/body-damage",
    component: lazy(() => import("@/pages/stages/BodyDamagePage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.BODY_DAMAGE.READ,
    meta: {
      title: "Body Damage",
    },
  },
  {
    key: "stages.body-damage.assess",
    path: "/stages/body-damage/:vehicleId/assess",
    component: lazy(() => import("@/pages/stages/BodyDamageAssessPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.BODY_DAMAGE.ASSESS,
    meta: {
      title: "Body Damage Assessment",
    },
  },
  {
    key: "stages.body-damage.quote",
    path: "/stages/body-damage/:vehicleId/quote",
    component: lazy(() => import("@/pages/stages/BodyDamageQuotePage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.BODY_DAMAGE.QUOTE,
    meta: {
      title: "Body Damage Quote",
    },
  },
  {
    key: "stages.body-damage.approval",
    path: "/stages/body-damage/:vehicleId/approval",
    component: lazy(() => import("@/pages/stages/BodyDamageApprovalPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.BODY_DAMAGE.APPROVAL,
    meta: {
      title: "Body Damage Approval",
    },
  },
  {
    key: "stages.body-damage.repair",
    path: "/stages/body-damage/:vehicleId/repair",
    component: lazy(() => import("@/pages/stages/BodyDamageRepairPage")),
    authority:
      rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.BODY_DAMAGE.COMPLETION,
    meta: {
      title: "Body Damage Repair",
    },
  },
  {
    key: "stages.media",
    path: "/stages/media",
    component: lazy(() => import("@/pages/stages/MediaPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MEDIA.READ,
    meta: {
      title: "Media",
    },
  },
  {
    key: "stages.media.valet",
    path: "/stages/media/:vehicleId/valet",
    component: lazy(() => import("@/pages/stages/MediaValetPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MEDIA.VALET,
    meta: {
      title: "Media Valet",
    },
  },
  {
    key: "stages.media.media",
    path: "/stages/media/:vehicleId/media",
    component: lazy(() => import("@/pages/stages/MediaMediaPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.MEDIA.MEDIA,
    meta: {
      title: "Media",
    },
  },
  {
    key: "stages.sale",
    path: "/stages/sale",
    component: lazy(() => import("@/pages/stages/SalePage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.SALE.READ,
    meta: {
      title: "Sale",
    },
  },
  {
    key: "stages.sale.post",
    path: "/stages/sale/:vehicleId/post",
    component: lazy(() => import("@/pages/stages/SalePostPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.SALE.POST,
    meta: {
      title: "Sale Post",
    },
  },
  {
    key: "stages.sale.sold",
    path: "/stages/sale/:vehicleId/sold",
    component: lazy(() => import("@/pages/stages/SaleSoldPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.SALE.SOLD,
    meta: {
      title: "Mark as Sold",
    },
  },
  {
    key: "stages.sale.sold-directly",
    path: "/stages/sale/:vehicleId/sold-directly",
    component: lazy(() => import("@/pages/stages/SaleSoldDirectlyPage")),
    authority: rolePermissions.ACTIVE_DEALER.VEHICLE.STAGE.SALE.SOLD_DIRECTLY,
    meta: {
      title: "Mark as Sold",
    },
  },
  {
    key: "stages.offsite",
    path: "/stages/:vehicleId/offsite",
    component: lazy(() => import("@/pages/stages/OffsitePage")),
    authority: [[UserRole.EMPLOYEE, [DealerRole.SALES]]],
    meta: {
      title: "Offsite",
    },
  },
  {
    key: "settings",
    path: "/settings/*",
    component: lazy(() => import("@/pages/SettingsPage")),
    authority: [],
    meta: {
      title: "Settings",
    },
  },
  {
    key: "site-settings",
    path: "/site-settings",
    component: lazy(() => import("@/pages/SiteSettingsPage")),
    authority: rolePermissions.SITE_SETTINGS.UK_VEHICLE_DATA_API_KEY.READ,
    meta: {
      title: "Site Settings",
    },
  },
];
