import { useMemo } from "react";
import { useAppSelector } from "@/store";

function useActiveDealer() {
  const user = useAppSelector((state) => state.auth.user);
  const session = useAppSelector((state) => state.auth.session);

  const activeDealer = useMemo(() => {
    return user.availableDealers?.find(
      (dealer) => dealer.id === session.activeDealerId,
    );
  }, [session.activeDealerId, user.availableDealers]);

  return activeDealer || null;
}

export default useActiveDealer;
