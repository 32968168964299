import RtkQueryService from "../RtkQueryService";
import { RTK_STAGE_ADMIN_TAG } from "./adminService";
import { RTK_STAGE_BODY_DAMAGE_TAG } from "./bodyDamageService";
import { RTK_STAGE_MECHANICAL_PREP_TAG } from "./mechanicalPrepService";
import { RTK_STAGE_MEDIA_TAG } from "./mediaService";
import { RTK_STAGE_SALE_TAG } from "./saleService";

const apiStageService = RtkQueryService.enhanceEndpoints({
  addTagTypes: [
    RTK_STAGE_ADMIN_TAG,
    RTK_STAGE_MECHANICAL_PREP_TAG,
    RTK_STAGE_BODY_DAMAGE_TAG,
    RTK_STAGE_MEDIA_TAG,
    RTK_STAGE_SALE_TAG,
  ],
}).injectEndpoints({
  endpoints: (build) => ({
    readStageAdmin: build.mutation<void, { vehicleId: string }>({
      query: ({ vehicleId }) => ({
        url: `/stage/${vehicleId}/read`,
        method: "POST",
      }),
      invalidatesTags: (_, __, { vehicleId }) => [
        { type: RTK_STAGE_ADMIN_TAG, id: vehicleId },
      ],
    }),
    readStageMechanicalPrep: build.mutation<void, { vehicleId: string }>({
      query: ({ vehicleId }) => ({
        url: `/stage/${vehicleId}/read`,
        method: "POST",
      }),
      invalidatesTags: (_, __, { vehicleId }) => [
        { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
      ],
    }),
    readStageBodyDamage: build.mutation<void, { vehicleId: string }>({
      query: ({ vehicleId }) => ({
        url: `/stage/${vehicleId}/read`,
        method: "POST",
      }),
      invalidatesTags: (_, __, { vehicleId }) => [
        { type: RTK_STAGE_BODY_DAMAGE_TAG, id: vehicleId },
      ],
    }),
    readStageMedia: build.mutation<void, { vehicleId: string }>({
      query: ({ vehicleId }) => ({
        url: `/stage/${vehicleId}/read`,
        method: "POST",
      }),
      invalidatesTags: (_, __, { vehicleId }) => [
        { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
      ],
    }),
    readStageSale: build.mutation<void, { vehicleId: string }>({
      query: ({ vehicleId }) => ({
        url: `/stage/${vehicleId}/read`,
        method: "POST",
      }),
      invalidatesTags: (_, __, { vehicleId }) => [
        { type: RTK_STAGE_SALE_TAG, id: vehicleId },
      ],
    }),
  }),
});

export const {
  useReadStageAdminMutation,
  useReadStageMechanicalPrepMutation,
  useReadStageBodyDamageMutation,
  useReadStageMediaMutation,
  useReadStageSaleMutation,
} = apiStageService;

export default apiStageService;
