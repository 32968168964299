import { combineReducers, Reducer, UnknownAction } from "redux";
import auth, { AuthState } from "./slices/auth";
import RtkQueryService from "@/services/RtkQueryService";

export type RootState = {
  auth: AuthState;
  [RtkQueryService.reducerPath]: ReturnType<typeof RtkQueryService.reducer>;
};

export interface AsyncReducers {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: Reducer<any, UnknownAction>;
}

const staticReducers = {
  auth,
  [RtkQueryService.reducerPath]: RtkQueryService.reducer,
};

const rootReducer =
  (asyncReducers?: AsyncReducers) =>
  (state: RootState, action: UnknownAction) => {
    const combinedReducer = combineReducers({
      ...staticReducers,
      ...asyncReducers,
    });
    return combinedReducer(state, action);
  };

export default rootReducer;
