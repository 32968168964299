import { io, Socket } from "socket.io-client";

// TODO: should be moved to a env file
const URL = import.meta.env.VITE_WS_URL!;

const CONNECTION_CHANGED_EVENT = "socketIoConnectionChanged";

class SocketIoService {
  socket: Socket | null = null;

  initialize(accessToken: string) {
    if (!this.socket) {
      this.socket = io(URL, {
        auth: {
          token: accessToken,
        },
      });

      this.socket.on("connect", () => {
        console.log("Socket connected");
        window.dispatchEvent(
          new CustomEvent(CONNECTION_CHANGED_EVENT, {
            detail: { socket: this.socket },
          }),
        );
      });

      this.socket.on("disconnect", () => {
        console.log("Socket disconnected");
      });
    }

    return this.socket;
  }

  async getSocket() {
    if (!this.socket) {
      await new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          reject(new Error("Socket initialization timed out"));
        }, 5000);

        const interval = setInterval(() => {
          if (this.socket) {
            clearInterval(interval);
            clearTimeout(timeout);
            resolve(this.socket);
          }
        }, 100);
      });
    }
    return this.socket!;
  }

  onTokenChange(callback: (socket: Socket) => () => void) {
    let isRun = false;
    let cleanupFn: (() => void) | undefined;

    const handleConnectionChange = (event: CustomEvent<{ socket: Socket }>) => {
      const newSocket = event.detail.socket;
      if (isRun) {
        if (cleanupFn) cleanupFn();
        cleanupFn = callback(newSocket);
      }
    };

    document.addEventListener(
      CONNECTION_CHANGED_EVENT,
      handleConnectionChange as EventListener,
    );

    return {
      startListening: () => {
        isRun = true;
        cleanupFn = callback(this.socket!);
      },
      stopListening: () => {
        isRun = false;
        if (cleanupFn) cleanupFn();
        document.removeEventListener(
          CONNECTION_CHANGED_EVENT,
          handleConnectionChange as EventListener,
        );
      },
    };
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}

const socketIoService = new SocketIoService();

export default socketIoService;
