import { Routes } from "@/@types/route";
import { lazy } from "react";

const authRoute: Routes = [
  {
    key: "login",
    path: "/login",
    component: lazy(() => import("@/pages/auth/LoginPage")),
    authority: [],
    meta: {
      title: "Login",
    },
  },
];

export default authRoute;
