import { Navigate, Outlet, useLocation } from "react-router-dom";
import appConfig from "@/configs/app.config";
import { useAppSelector } from "@/store";

const PublicRoute = () => {
  const location = useLocation();
  const authenticated = useAppSelector(
    (state) => state.auth.session.authenticated,
  );

  if (authenticated && location.search) {
    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get("redirectTo");
    if (redirectTo) {
      return <Navigate to={redirectTo} replace />;
    }
  }

  if (authenticated) {
    return <Navigate to={appConfig.authenticatedEntryPath} replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
