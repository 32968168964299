import {
  BoltIcon,
  Building2Icon,
  CarFrontIcon,
  ChartLineIcon,
  ClipboardCheckIcon,
  ClipboardPlusIcon,
  CogIcon,
  FileClockIcon,
  FileCog2Icon,
  GlobeIcon,
  GroupIcon,
  ImagesIcon,
  SettingsIcon,
  ShieldIcon,
  TagIcon,
  Users2Icon,
} from "lucide-react";

export const navigationIcon = {
  home: <GlobeIcon />,
  make: <TagIcon />,
  dealer: <CarFrontIcon />,
  dealerGroup: <GroupIcon />,
  user: <Users2Icon />,
  fileConfig: <FileCog2Icon />,
  queueJob: <FileClockIcon />,
  employee: <Users2Icon />,
  vehicle: <CarFrontIcon />,
  crmConfirmation: <ClipboardCheckIcon />,
  stageInfleet: <ClipboardPlusIcon />,
  stageAdmin: <BoltIcon />,
  stageIct: <Building2Icon />,
  stageMechanicalPrep: <CogIcon />,
  stageBodyDamage: <ShieldIcon />,
  stageMedia: <ImagesIcon />,
  stageSale: <ChartLineIcon />,
  siteSetting: <SettingsIcon />,
  agencyStock: <Building2Icon />,
} as const satisfies {
  [key: string]: JSX.Element;
};

export type NavigationIconKey = keyof typeof navigationIcon;
