import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { Button } from "../../ui/Button";
import {
  setUser,
  signOutSuccess,
  updateSession,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  ChevronDownIcon,
  CircleHelpIcon,
  KeyIcon,
  Loader2Icon,
  LogOutIcon,
  MapPinIcon,
  MoonStarIcon,
  SunIcon,
  SunMoonIcon,
  UserCog2Icon,
  UserIcon,
} from "lucide-react";
import { useLogoutMutation } from "@/services/AuthService";
import {
  useSwitchDealerMutation,
  useSwitchRoleMutation,
} from "@/services/UserService";
import useActiveDealer from "@/hooks/useActiveDealer";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { UserRole } from "@/types/role";
import SwitchRoleDialog from "../../shared/SwitchRoleDialog";
import { toast } from "sonner";
import SwitchDealerDialog from "../../shared/SwitchDealerDialog";
import useClearRtk from "@/hooks/useClearRtk";
import useMediaQuery from "@/hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import Helmet from "../Helmet";
import SearchVehicle from "./SearchVehicle";
import logoLight from "@/assets/images/logo-light.png";
import logoDark from "@/assets/images/logo-dark.png";
import useCheckRolePermission from "@/hooks/useCheckRolePermission";
import { rolePermissions } from "@/configs/rolePermissions.config";
import { handleAxiosError } from "@/utils/httpError";
import { useTheme } from "@/components/context/ThemeContext";
import ChangeThemeDialog from "@/components/shared/ChangeThemeDialog";
import { Menu } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/DropdownMenu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/Sheet";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/Avatar";
import { getTwoLetterAlias } from "@/utils/string";
import { navigationConfig } from "@/configs/navigation.config";
import { protectedRoutes } from "@/configs/routes";
import { Route } from "@/@types/route";
import { navigationIcon } from "@/configs/navigationIcon.config";
import useRefreshUser from "@/hooks/useRefreshUser";

const isMac = navigator.userAgent.toLowerCase().indexOf("mac") >= 0;
const isWin = navigator.userAgent.toLowerCase().indexOf("win") >= 0;
const isLinux = navigator.userAgent.toLowerCase().indexOf("linux") >= 0;

const navigation = navigationConfig.map((config) => {
  const route = protectedRoutes.find(
    (route) => route.key === config.key,
  ) as Route;
  return { ...config, path: route.path, authority: route.authority };
});

const DashboardLayout = () => {
  const navigate = useNavigate();
  const clearRtk = useClearRtk();
  const dispatch = useAppDispatch();
  const refreshUser = useRefreshUser();
  const { theme, activeTheme } = useTheme();
  const { check } = useCheckRolePermission();
  const activeDealer = useActiveDealer();
  const user = useAppSelector((state) => state.auth.user);
  const session = useAppSelector((state) => state.auth.session);

  const canSearchVehicle = check(
    rolePermissions.ACTIVE_DEALER_GROUP.VEHICLE.READ,
  );

  const showSite =
    session.activeRole === UserRole.SUPER_ADMIN ||
    session.activeRole === UserRole.EMPLOYEE;

  const currentSite = user.availableDealers?.find(
    (dealer) => dealer.id === session.activeDealerId,
  );

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const [logout, { isLoading: isLoggingOut }] = useLogoutMutation();
  const [switchRole, { isLoading: isSwitchingRole }] = useSwitchRoleMutation();
  const [switchDealer, { isLoading: isSwitchingDealer }] =
    useSwitchDealerMutation();

  const [switchRoleOpen, setSwitchRoleOpen] = useState(false);
  const [switchDealerOpen, setSwitchDealerOpen] = useState(false);
  const [changeThemeDialogOpen, setChangeThemeDialogOpen] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const allowedLinks = useMemo(
    () => navigation.filter((link) => check(link.authority)),
    [check],
  );

  const onLogout = async () => {
    try {
      // Keep it async to avoid auto redirect
      logout().unwrap();
    } catch (err: unknown) {
      handleAxiosError(err, (message) => {
        toast.error("Failed to log out.", { description: message });
      });
    } finally {
      dispatch(setUser(null));
      dispatch(signOutSuccess());
      navigate("/login");
      clearRtk();
    }
  };

  const onSwitchRoleClick = useCallback(() => {
    const availableMainRoles =
      user.availableDealers?.find((d) => d.id === session.activeDealerId)
        ?.mainRoles || [];

    if (availableMainRoles.length > 1) {
      setSwitchDealerOpen(false);
      setSwitchRoleOpen(true);
    }
  }, [session.activeDealerId, user.availableDealers]);

  const onCancelSwitchRoleClick = () => {
    setSwitchRoleOpen(false);
  };

  const onSwitchRole = async (newRole: UserRole) => {
    try {
      const resp = await switchRole({
        newRole,
        refreshToken: session.refreshToken!,
      }).unwrap();

      setSwitchRoleOpen(false);
      dispatch(updateSession(resp.data));
      clearRtk();
      navigate("/");

      toast.success("Role switched successfully.");
    } catch (err: unknown) {
      handleAxiosError(err, (message) => {
        toast.error("Failed to switch role.", {
          description: message,
        });
      });
    }
  };

  const onSwitchDealerClick = useCallback(() => {
    if (user.availableDealers && user.availableDealers.length > 1) {
      setSwitchRoleOpen(false);
      setSwitchDealerOpen(true);
    }
  }, [user.availableDealers]);

  const onCancelSwitchDealerClick = () => {
    setSwitchDealerOpen(false);
  };

  const onSwitchDealer = async (dealerId: string, role: UserRole) => {
    try {
      const resp = await switchDealer({
        newDealerId: dealerId,
        newRole: role,
        refreshToken: session.refreshToken!,
      }).unwrap();

      setSwitchDealerOpen(false);
      dispatch(updateSession(resp.data));
      clearRtk();
      navigate("/");

      toast.success("Location switched successfully.");
    } catch (err: unknown) {
      handleAxiosError(err, (message) => {
        toast.error("Failed to switch location.", {
          description: message,
        });
      });
    }
  };

  const onOpenChangeThemeDialog = () => {
    setChangeThemeDialogOpen(true);
  };

  const onCloseChangeThemeDialog = () => {
    setChangeThemeDialogOpen(false);
  };

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "F1") {
        return window.open("/docs", "_blank");
      } else if ((e.key === "s" || e.key === "S") && (e.ctrlKey || e.altKey)) {
        onSwitchDealerClick();
      } else if ((e.key === "r" || e.key === "R") && (e.ctrlKey || e.altKey)) {
        onSwitchRoleClick();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [onSwitchDealerClick, onSwitchRoleClick]);

  let logoUrl: string | null = null;

  if (activeTheme === "light" && activeDealer) {
    logoUrl = activeDealer.groupLogoLightUrl || activeDealer.groupLogoDarkUrl;
  } else if (activeTheme === "dark" && activeDealer) {
    logoUrl = activeDealer.groupLogoDarkUrl || activeDealer.groupLogoLightUrl;
  }

  // Refresh user data on mount
  useEffect(() => {
    refreshUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet />
      <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
        <div className="hidden border-r bg-card md:block">
          <div className="sticky left-0 top-0 flex h-full max-h-screen flex-col gap-2">
            <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
              <Link to="/">
                <img
                  src={activeTheme === "light" ? logoLight : logoDark}
                  alt="logo"
                  className="h-7 w-auto"
                />
              </Link>
            </div>
            <div className="flex-1">
              <nav className="grid items-start gap-1 px-2 text-sm font-medium lg:px-4">
                {allowedLinks.map((link) => (
                  <NavLink
                    to={link.path}
                    key={link.key}
                    className={({ isActive }) =>
                      cn(
                        "flex items-center gap-3 rounded px-3 py-2 transition-all [&>svg]:h-6 [&>svg]:w-6",
                        isActive
                          ? "bg-muted text-primary"
                          : "text-muted-foreground hover:bg-muted hover:text-foreground",
                      )
                    }
                  >
                    {navigationIcon[link.icon]}
                    {link.title}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col overflow-hidden">
          <header className="sticky top-0 z-10 flex h-14 items-center justify-between gap-4 border-b bg-card px-4 lg:h-[60px] lg:px-6">
            <div className="flex items-center gap-4">
              <Sheet open={sidebarOpen} onOpenChange={setSidebarOpen}>
                <SheetTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="shrink-0 md:hidden"
                  >
                    <Menu className="h-5 w-5" />
                    <span className="sr-only">Toggle navigation menu</span>
                  </Button>
                </SheetTrigger>
                <SheetContent side="left" className="flex w-64 flex-col">
                  <nav className="grid gap-2 text-lg font-medium">
                    <Link to="/" onClick={() => setSidebarOpen(false)}>
                      <img
                        src={activeTheme === "light" ? logoLight : logoDark}
                        alt="logo"
                        className="mb-4 h-7 w-auto"
                      />
                    </Link>
                    {allowedLinks.map((link) => (
                      <NavLink
                        key={link.key}
                        to={link.path}
                        onClick={() => setSidebarOpen(false)}
                        className={({ isActive }) =>
                          cn(
                            "mx-[-0.65rem] flex items-center gap-3 rounded px-3 py-2 text-sm [&>svg]:h-6 [&>svg]:w-6",
                            isActive
                              ? "bg-muted text-primary"
                              : "text-muted-foreground hover:bg-muted hover:text-foreground",
                          )
                        }
                      >
                        {navigationIcon[link.icon]}
                        {link.title}
                      </NavLink>
                    ))}
                  </nav>
                </SheetContent>
              </Sheet>
              {logoUrl && (
                <img
                  alt="dealer group logo"
                  src={logoUrl}
                  className="h-7 w-auto shrink-0 md:hidden"
                />
              )}
              {showSite && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="-ml-3 hidden max-w-80 items-center text-muted-foreground md:flex md:max-w-60 lg:max-w-80 xl:max-w-96 2xl:max-w-max"
                  onClick={onSwitchDealerClick}
                >
                  <MapPinIcon className="mr-1 h-4 w-4 shrink-0" />
                  <span className="truncate">{currentSite?.name}</span>
                </Button>
              )}
            </div>
            {logoUrl && (
              <img
                src={logoUrl}
                alt="dealer group logo"
                className="absolute left-1/2 top-1/2 mr-2 hidden h-8 w-auto -translate-x-1/2 -translate-y-1/2 transform md:block"
              />
            )}
            <div className="flex items-center gap-4">
              {canSearchVehicle && <SearchVehicle />}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    type="button"
                    variant="ghost"
                    size={isMobile ? "icon" : "default"}
                    className="space-x-3"
                  >
                    <Avatar className="h-7 w-7">
                      {user.profilePhotoUrl && (
                        <AvatarImage
                          src={user.profilePhotoUrl}
                          alt={user.name}
                          className="object-cover"
                        />
                      )}
                      <AvatarFallback className="bg-slate-200 dark:bg-slate-800">
                        {getTwoLetterAlias(user.name!)}
                      </AvatarFallback>
                    </Avatar>
                    <span className="sis hidden max-w-40 overflow-hidden truncate lg:block">
                      {user.name}
                    </span>
                    <ChevronDownIcon className="hidden h-4 w-4 lg:block" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-56">
                  <DropdownMenuLabel>
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col space-y-0.5">
                        <span>My Account</span>
                        <span className="text-xs font-normal text-muted-foreground">
                          {user.email}
                        </span>
                      </div>
                      <Button
                        type="button"
                        size="icon"
                        variant="ghost"
                        className="h-auto w-auto p-2"
                        asChild
                      >
                        <Link to={"/docs"} target="_blank" rel="noreferrer">
                          <CircleHelpIcon className="h-4 w-4" />
                        </Link>
                      </Button>
                    </div>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => navigate("/settings/profile")}
                    >
                      <UserIcon className="mr-2 h-4 w-4" />
                      <span>Profile</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={() => navigate("/settings/password")}
                    >
                      <KeyIcon className="mr-2 h-4 w-4" />
                      <span>Change Password</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={onSwitchDealerClick}
                      disabled={
                        user?.availableDealers
                          ? user.availableDealers.length < 2
                          : true
                      }
                    >
                      <MapPinIcon className="mr-2 h-4 w-4" />
                      <div className="flex grow items-center justify-between">
                        <span>Switch Location</span>
                        <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                          <span className="text-xs">
                            {isMac && "^S"}
                            {isWin && "Alt+S"}
                            {isLinux && "Alt+S"}
                          </span>
                        </kbd>
                      </div>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer"
                      onClick={onSwitchRoleClick}
                      disabled={
                        activeDealer?.mainRoles
                          ? activeDealer.mainRoles.length < 2
                          : true
                      }
                    >
                      <UserCog2Icon className="mr-2 h-4 w-4" />
                      <div className="flex grow items-center justify-between">
                        <span>Switch Role</span>
                        <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                          <span className="text-xs">
                            {isMac && "^R"}
                            {isWin && "Alt+R"}
                            {isLinux && "Alt+R"}
                          </span>
                        </kbd>
                      </div>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={onOpenChangeThemeDialog}
                  >
                    {theme === "system" && (
                      <SunMoonIcon className="mr-2 h-4 w-4" />
                    )}
                    {theme === "dark" && (
                      <MoonStarIcon className="mr-2 h-4 w-4" />
                    )}
                    {theme === "light" && <SunIcon className="mr-2 h-4 w-4" />}
                    <div>
                      Theme: <span className="capitalize">{theme}</span>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={onLogout}
                    className="cursor-pointer"
                  >
                    {isLoggingOut ? (
                      <Loader2Icon className="mr-2 h-4 w-4 text-destructive" />
                    ) : (
                      <LogOutIcon className="mr-2 h-4 w-4 text-destructive" />
                    )}
                    <span className="text-destructive">Log out</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </header>
          <main className="flex-1 bg-muted/50 p-4 lg:p-6">
            <Suspense
              fallback={
                <div className="flex h-full items-center justify-center">
                  <Loader2Icon className="h-8 w-8 animate-spin" />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </main>
        </div>
      </div>

      <SwitchRoleDialog
        open={switchRoleOpen}
        activeRole={session.activeRole as UserRole}
        loading={isSwitchingRole}
        onSubmit={onSwitchRole}
        onClose={onCancelSwitchRoleClick}
      />

      <SwitchDealerDialog
        open={switchDealerOpen}
        activeDealerId={session.activeDealerId!}
        activeRole={session.activeRole as UserRole}
        availableDealers={user.availableDealers!}
        loading={isSwitchingDealer}
        onSubmit={onSwitchDealer}
        onClose={onCancelSwitchDealerClick}
      />

      <ChangeThemeDialog
        open={changeThemeDialogOpen}
        onClose={onCloseChangeThemeDialog}
      />
    </>
  );
};

export default DashboardLayout;
