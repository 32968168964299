import isEqual from "lodash.isequal";
import RtkQueryService from "./RtkQueryService";

interface DealerMake {
  id: string;
  name: string;
  active: boolean;
  featured: boolean;
  logoUrl: string | null;
  createdAt: string;
}

interface GetDealerMakesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  active: string; // 'true' | 'false' | ''
  featured: string; // 'true' | 'false' | ''
}

export interface GetDealerMakesResponse {
  data: {
    data: DealerMake[];
    totalCount: number;
    filteredCount: number;
  };
}

interface UpdateDealerMakeStatusRequest {
  id: string;
  data: {
    active: boolean;
  };
}

interface UpdateDealerMakeStatusResponse {
  message: string;
}

interface UpdateDealerMakeFeaturedRequest {
  featured: boolean;
}

interface UpdateDealerMakeFeaturedResponse {
  message: string;
}

export interface GetDealerMakeOptionsRequest {
  search: string;
  pageIndex: number;
  pageSize: number;
  includeIds?: string[];
}

export interface GetDealerMakeOptionsResponse {
  data: {
    id: string;
    name: string;
    logoUrl: string | null;
  }[];
  filteredCount: number;
}

export interface GetDealerMakeModelOptionsResponse {
  data: {
    id: string;
    name: string;
  }[];
}

export const RTK_DEALER_MAKES_TAG = "DealerMakes" as const;

export const RTK_DEALER_MAKE_OPTIONS_TAG = "DealerMakeOptions" as const;
export const RTK_DEALER_MAKE_MODEL_OPTIONS_TAG =
  "DealerMakeModelOptions" as const;

const apiDealerMakes = RtkQueryService.enhanceEndpoints({
  addTagTypes: [
    RTK_DEALER_MAKES_TAG,
    RTK_DEALER_MAKE_OPTIONS_TAG,
    RTK_DEALER_MAKE_MODEL_OPTIONS_TAG,
  ],
}).injectEndpoints({
  endpoints: (build) => ({
    getDealerMakes: build.query<GetDealerMakesResponse, GetDealerMakesRequest>({
      query: (params) => ({
        url: `/dealer/makes`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id: makeId }) => ({
                type: RTK_DEALER_MAKES_TAG,
                id: makeId,
              })),
              { type: RTK_DEALER_MAKES_TAG, id: "LIST" },
            ]
          : [{ type: RTK_DEALER_MAKES_TAG, id: "LIST" }],
    }),
    updateDealerMakeStatus: build.mutation<
      UpdateDealerMakeStatusResponse,
      UpdateDealerMakeStatusRequest
    >({
      query: ({ id, data }) => ({
        url: `/dealer/makes/${id}/status`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { id }) =>
        result
          ? [
              { type: RTK_DEALER_MAKES_TAG, id: id },
              { type: RTK_DEALER_MAKE_OPTIONS_TAG, id: id },
            ]
          : [],
    }),
    updateDealerMakeFeatured: build.mutation<
      UpdateDealerMakeFeaturedResponse,
      { id: string; data: UpdateDealerMakeFeaturedRequest }
    >({
      query: ({ id, data }) => ({
        url: `/dealer/makes/${id}/featured`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { id }) =>
        result
          ? [
              { type: RTK_DEALER_MAKES_TAG, id: id },
              { type: RTK_DEALER_MAKE_OPTIONS_TAG, id: id },
            ]
          : [],
    }),
    // ---
    getActiveDealerMakeOptions: build.query<
      GetDealerMakeOptionsResponse,
      GetDealerMakeOptionsRequest
    >({
      query: (params) => ({
        url: `/options/makes`,
        method: "GET",
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const newIncludeIds = [...(queryArgs.includeIds ?? [])].sort();
        return {
          ...queryArgs,
          includeIds: newIncludeIds,
          pageIndex: undefined,
        };
      },
      merge: (currentCacheData, responseData) => {
        const currentDataIds = currentCacheData?.data.map(({ id }) => id) ?? [];
        currentCacheData.data.push(
          ...responseData.data.filter(({ id }) => !currentDataIds.includes(id)),
        );
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_DEALER_MAKE_OPTIONS_TAG,
                id,
              })),
              { type: RTK_DEALER_MAKE_OPTIONS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_DEALER_MAKE_OPTIONS_TAG, id: "LIST" }],
    }),
    getActiveDealerMakeModelOptions: build.query<
      GetDealerMakeModelOptionsResponse,
      { makeId: string }
    >({
      query: ({ makeId }) => ({
        url: `/options/makes/${makeId}/models`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_DEALER_MAKE_MODEL_OPTIONS_TAG,
                id,
              })),
              { type: RTK_DEALER_MAKE_MODEL_OPTIONS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_DEALER_MAKE_MODEL_OPTIONS_TAG, id: "LIST" }],
    }),
  }),
});

export const {
  useGetDealerMakesQuery,
  useUpdateDealerMakeStatusMutation,
  useUpdateDealerMakeFeaturedMutation,
  useGetActiveDealerMakeOptionsQuery,
  useGetActiveDealerMakeModelOptionsQuery,
} = apiDealerMakes;

export default apiDealerMakes;
