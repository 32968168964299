import RtkQueryService from "../RtkQueryService";

interface SearchRequest {
  registration: string;
}

interface SearchResponse {
  data: {
    id: string;
    dealerId: string;
    dealerName: string;
    sourceName: string;
    modelName: string | null;
    modelCustom: string | null;
    registration: string | null;
    makeName: string | null;
    makeLogoUrl: string | null;
    shortChassis: string;
    createdAt: string;
    lastUpdatedAt: string;
  }[];
}

export const RTK_ACTIVE_DEALER_GROUP_VEHICLE_SEARCH_TAG =
  "ActiveDealerGroupVehicleSearch" as const;

const apiActiveDealerGroupVehicles = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_ACTIVE_DEALER_GROUP_VEHICLE_SEARCH_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    searchActiveDealerGroupVehicles: build.query<SearchResponse, SearchRequest>(
      {
        query: (params) => ({
          url: `/active-dealer-group/vehicles/search`,
          method: "GET",
          params,
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({ id }) => ({
                  type: RTK_ACTIVE_DEALER_GROUP_VEHICLE_SEARCH_TAG,
                  id: id,
                })),
                {
                  type: RTK_ACTIVE_DEALER_GROUP_VEHICLE_SEARCH_TAG,
                  id: "LIST",
                },
              ]
            : [
                {
                  type: RTK_ACTIVE_DEALER_GROUP_VEHICLE_SEARCH_TAG,
                  id: "LIST",
                },
              ],
      },
    ),
  }),
});

export const { useLazySearchActiveDealerGroupVehiclesQuery } =
  apiActiveDealerGroupVehicles;

export default apiActiveDealerGroupVehicles;
