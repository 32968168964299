import { useEffect, useState } from "react";
import ThemeContext, { ActiveTheme, Theme } from "./theme.context";

const LOCAL_STORAGE_KEY = "theme";

const getDefaultTheme = (): Theme => {
  const savedTheme = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (
    savedTheme === "light" ||
    savedTheme === "dark" ||
    savedTheme === "system"
  ) {
    return savedTheme;
  }

  return "system";
};

const getSystemTheme = (): ActiveTheme => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
};

const updateDocumentClass = (theme: ActiveTheme) => {
  const root = window.document.documentElement;
  root.classList.toggle("dark", theme === "dark");
};

const defaultTheme = getDefaultTheme();

export function ThemeProvider({ children }: React.PropsWithChildren) {
  const [theme, setTheme] = useState(defaultTheme);
  const [activeTheme, setActiveTheme] = useState<ActiveTheme>(
    defaultTheme === "system" ? getSystemTheme() : defaultTheme,
  );

  const onChangeTheme = (newTheme: Theme) => {
    setTheme(newTheme);
    localStorage.setItem(LOCAL_STORAGE_KEY, newTheme);
    if (newTheme === "system") {
      const systemTheme = getSystemTheme();
      setActiveTheme(systemTheme);
      updateDocumentClass(systemTheme);
    } else {
      setActiveTheme(newTheme);
      updateDocumentClass(newTheme);
    }
  };

  useEffect(() => {
    if (theme === "system") {
      const systemTheme = getSystemTheme();
      setActiveTheme(systemTheme);
      updateDocumentClass(systemTheme);
    } else {
      setActiveTheme(theme);
      updateDocumentClass(theme);
    }
  }, [theme]);

  useEffect(() => {
    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      if (theme === "system") {
        const newTheme: ActiveTheme = e.matches ? "dark" : "light";
        setActiveTheme(newTheme);
        updateDocumentClass(newTheme);
      }
    };

    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQueryList.addEventListener("change", handleSystemThemeChange);

    return () => {
      mediaQueryList.removeEventListener("change", handleSystemThemeChange);
    };
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, activeTheme, onChangeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
