import RtkQueryService from "./RtkQueryService";

interface GetApiKeyResponse {
  data: {
    id: string;
    name: string;
    key: string;
    active: boolean;
  }[];
}

interface ChangeActiveApiKeyRequest {
  ukVehicleDataApiKeyId: string;
}

interface ChangeActiveApiKeyResponse {
  message: string;
}

const RTK_API_KEY_TAG = "SiteSettings_UkVehicleDataApiKey" as const;

const apiSiteSettings = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_API_KEY_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getUkVehicleDataApiKeys: build.query<GetApiKeyResponse, void>({
      query: (params) => ({
        url: `/site-settings/uk-vehicle-data-api-keys`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_API_KEY_TAG,
                id,
              })),
              { type: RTK_API_KEY_TAG, id: "LIST" },
            ]
          : [{ type: RTK_API_KEY_TAG, id: "LIST" }],
    }),
    changeActiveUkVehicleDataApiKey: build.mutation<
      ChangeActiveApiKeyResponse,
      ChangeActiveApiKeyRequest
    >({
      query: (data) => ({
        url: `/site-settings/uk-vehicle-data-api-keys/active`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RTK_API_KEY_TAG, id: "LIST" }] : [],
    }),
  }),
});

export const {
  useGetUkVehicleDataApiKeysQuery,
  useChangeActiveUkVehicleDataApiKeyMutation,
} = apiSiteSettings;

export default apiSiteSettings;
