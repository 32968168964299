import { RTK_VEHICLE_TAG } from "../VehicleService";
import RtkQueryService from "../RtkQueryService";
import { RTK_REPORT_TAG } from "../ReportService";

interface MessageResponse {
  message: string;
}

interface GetStageMediaVehiclesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  source: string[];
  saleType: string[];
  make: string[];
  status: string[];
}

export interface GetStageMediaVehiclesResponse {
  data: {
    data: {
      id: string;
      modelName: string | null;
      modelCustom: string | null;
      registration: string | null;
      sourceName: string;
      saleTypeName: string | null;
      makeName: string | null;
      makeLogoUrl: string | null;
      mileage?: number;
      shortChassis: string;
      stage: string;
      stageStatus: string;
      createdAt: string;
      lastUpdatedAt: string;
      isViewed: boolean;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindStageMediaVehicleResponse {
  data: {
    valetEtaEcd: string | null;
    mediaEtaEcd: string | null;
    valetEtaEcdHistory: {
      id: string;
      prevEtaEcd: string | null;
      newEtaEcd: string;
      note: string;
      createdAt: string;
      user: {
        id: string;
        name: string;
      };
    }[];
    mediaEtaEcdHistory: {
      id: string;
      prevEtaEcd: string | null;
      newEtaEcd: string;
      note: string;
      createdAt: string;
      user: {
        id: string;
        name: string;
      };
    }[];
    media: {
      id: string;
      description: string | null;
      file: {
        name: string;
        url: string;
      };
      user: {
        id: string;
        name: string;
      } | null;
      stage: {
        id: string;
        stage: string;
        status: string;
      } | null;
      createdAt: string;
    }[];
    note: string;
  };
}

interface ValetDraftStageMediaRequest {
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

interface ValetStageMediaRequest {
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

interface StoreStagMediaFilesRequest {
  fileIds: string[];
}

interface UpdateStageMediaFileRequest {
  description: string;
}

interface MediaDraftStageMediaRequest {
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

interface MediaStageMediaRequest {
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

export const RTK_STAGE_MEDIA_TAG = "StageMedia" as const;

const apiStageMedia = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_STAGE_MEDIA_TAG, RTK_VEHICLE_TAG, RTK_REPORT_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getStageMediaVehicles: build.query<
      GetStageMediaVehiclesResponse,
      GetStageMediaVehiclesRequest
    >({
      query: (params) => ({
        url: `/stage/media`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_STAGE_MEDIA_TAG,
                id: id,
              })),
              { type: RTK_STAGE_MEDIA_TAG, id: "LIST" },
            ]
          : [{ type: RTK_STAGE_MEDIA_TAG, id: "LIST" }],
    }),
    findStageMediaVehicle: build.query<FindStageMediaVehicleResponse, string>({
      query: (vehicleId) => ({
        url: `/stage/media/${vehicleId}`,
        method: "GET",
      }),
      providesTags: (result, _, vehicleId) =>
        result ? [{ type: RTK_STAGE_MEDIA_TAG, id: vehicleId }] : [],
    }),
    // ---
    valetDraftStageMedia: build.mutation<
      MessageResponse,
      { vehicleId: string; data: ValetDraftStageMediaRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/media/${vehicleId}/valet/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
            ]
          : [],
    }),
    valetStageMedia: build.mutation<
      MessageResponse,
      { vehicleId: string; data: ValetStageMediaRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/media/${vehicleId}/valet`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    // ---
    storeStageMediaFiles: build.mutation<
      MessageResponse,
      {
        vehicleId: string;
        data: StoreStagMediaFilesRequest;
      }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/media/${vehicleId}/media/files`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
            ]
          : [],
    }),
    updateStageMediaFile: build.mutation<
      MessageResponse,
      {
        vehicleId: string;
        fileId: string;
        data: UpdateStageMediaFileRequest;
      }
    >({
      query: ({ vehicleId, fileId, data }) => ({
        url: `/stage/media/${vehicleId}/media/files/${fileId}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
            ]
          : [],
    }),
    deleteStageMediaFile: build.mutation<
      MessageResponse,
      { vehicleId: string; fileId: string }
    >({
      query: ({ vehicleId, fileId }) => ({
        url: `/stage/media/${vehicleId}/media/files/${fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
            ]
          : [],
    }),
    // ---
    mediaDraftStageMedia: build.mutation<
      MessageResponse,
      { vehicleId: string; data: MediaDraftStageMediaRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/media/${vehicleId}/media/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
            ]
          : [],
    }),
    mediaStageMedia: build.mutation<
      MessageResponse,
      { vehicleId: string; data: MediaStageMediaRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/media/${vehicleId}/media`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MEDIA_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
  }),
});

export const {
  useGetStageMediaVehiclesQuery,
  useFindStageMediaVehicleQuery,
  //
  useValetDraftStageMediaMutation,
  useValetStageMediaMutation,
  //
  useStoreStageMediaFilesMutation,
  useUpdateStageMediaFileMutation,
  useDeleteStageMediaFileMutation,
  //
  useMediaDraftStageMediaMutation,
  useMediaStageMediaMutation,
} = apiStageMedia;

export default apiStageMedia;
