import RtkQueryService from "./RtkQueryService";

interface Source {
  id: string;
  code: string;
  name: string;
}

export interface GetSourceOptionsResponse {
  data: Source[];
}

export const RTK_SOURCES_TAG = "Sources" as const;
export const RTK_SOURCE_OPTIONS_TAG = "SourceOptions" as const;

const apiSource = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_SOURCES_TAG, RTK_SOURCE_OPTIONS_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getSourceOptions: build.query<GetSourceOptionsResponse, void>({
      query: () => ({
        url: `/options/sources`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: RTK_SOURCE_OPTIONS_TAG,
                id,
              })),
              { type: RTK_SOURCE_OPTIONS_TAG, id: "LIST" },
            ]
          : [{ type: RTK_SOURCE_OPTIONS_TAG, id: "LIST" }],
    }),
  }),
});

export const { useGetSourceOptionsQuery } = apiSource;

export default apiSource;
