import { RTK_VEHICLE_TAG } from "../VehicleService";
import RtkQueryService from "../RtkQueryService";
import { RTK_REPORT_TAG } from "../ReportService";

interface GetStageAdminVehiclesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  source: string[];
  saleType: string[];
  make: string[];
}

export interface GetStageAdminVehiclesResponse {
  data: {
    data: {
      id: string;
      modelName: string | null;
      modelCustom: string | null;
      registration: string | null;
      sourceName: string;
      saleTypeName: string | null;
      makeName: string | null;
      makeLogoUrl: string | null;
      mileage?: number;
      shortChassis: string;
      stage: string;
      stageStatus: string;
      createdAt: string;
      lastUpdatedAt: string;
      isViewed: boolean;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

interface AdminConfigureRequest {
  linkToCrm: boolean;
  vsbToWorkshop: boolean;
  vsbToBodyshop: boolean;
  note: string;
}

interface AdminConfigureResponse {
  message: string;
}

export const RTK_STAGE_ADMIN_TAG = "StageAdmin" as const;

const apiStageAdmin = RtkQueryService.enhanceEndpoints({
  addTagTypes: [RTK_STAGE_ADMIN_TAG, RTK_VEHICLE_TAG, RTK_REPORT_TAG],
}).injectEndpoints({
  endpoints: (build) => ({
    getStageAdminVehicles: build.query<
      GetStageAdminVehiclesResponse,
      GetStageAdminVehiclesRequest
    >({
      query: (params) => ({
        url: `/stage/admin`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_STAGE_ADMIN_TAG,
                id: id,
              })),
              { type: RTK_STAGE_ADMIN_TAG, id: "LIST" },
            ]
          : [{ type: RTK_STAGE_ADMIN_TAG, id: "LIST" }],
    }),
    stageAdminConfigure: build.mutation<
      AdminConfigureResponse,
      { vehicleId: string; data: AdminConfigureRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/admin/${vehicleId}/configure`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_ADMIN_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
  }),
});

export const { useGetStageAdminVehiclesQuery, useStageAdminConfigureMutation } =
  apiStageAdmin;

export default apiStageAdmin;
