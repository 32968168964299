import { Navigate, Outlet, useLocation } from "react-router-dom";
import appConfig from "@/configs/app.config";
import { useAppSelector } from "@/store";

const ProtectedRoute = () => {
  const location = useLocation();
  const authenticated = useAppSelector(
    (state) => state.auth.session.authenticated,
  );

  if (!authenticated) {
    const redirectTo = `${location.pathname}${location.search}`;
    const encodedRedirectTo = encodeURIComponent(redirectTo);
    return (
      <Navigate
        to={`${appConfig.unAuthenticatedEntryPath}?redirectTo=${encodedRedirectTo}`}
        replace
      />
    );
  }

  if (location.search) {
    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get("redirectTo");
    if (redirectTo) {
      return <Navigate to={redirectTo} replace />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoute;
