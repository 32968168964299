import { RTK_VEHICLE_TAG } from "../VehicleService";
import RtkQueryService from "../RtkQueryService";
import { RTK_REPORT_TAG } from "../ReportService";

interface MessageResponse {
  message: string;
}

interface GetStageMechanicalPrepVehiclesRequest {
  pageSize: number;
  pageIndex: number;
  search: string;
  sortBy: string;
  sortOrder: string;
  source: string[];
  saleType: string[];
  make: string[];
  status: string[];
}

export interface GetStageMechanicalPrepVehiclesResponse {
  data: {
    data: {
      id: string;
      modelName: string | null;
      modelCustom: string | null;
      registration: string | null;
      sourceName: string;
      saleTypeName: string | null;
      makeName: string | null;
      makeLogoUrl: string | null;
      mileage?: number;
      shortChassis: string;
      stage: string;
      stageStatus: string;
      createdAt: string;
      lastUpdatedAt: string;
      isViewed: boolean;
    }[];
    totalCount: number;
    filteredCount: number;
  };
}

interface FindStageMechanicalPrepVehicleResponse {
  data: {
    quoteApplicable: boolean | null;
    url: string | null;
    nextMotDueDate: string | null;
    motCarriedOut: boolean | null;
    serviceCarriedOut: boolean | null;
    etaEcd: string | null;
    etaEcdHistory: {
      id: string;
      prevEtaEcd: string | null;
      newEtaEcd: string;
      note: string;
      createdAt: string;
      user: {
        id: string;
        name: string;
      };
    }[];
    files: {
      id: string;
      description: string | null;
      file: {
        name: string;
        url: string;
      };
      user: {
        id: string;
        name: string;
      } | null;
      stage: {
        id: string;
        stage: string;
        stageStatus: string;
      } | null;
      createdAt: string;
    }[];
    note: string;
  };
}

interface FindMotResponse {
  data: {
    nextMotDueDate: string | null;
  };
}

interface AssessStageMechanicalPrepRequest {
  note: string;
}

interface StoreStageMechanicalPrepFilesRequest {
  fileIds: string[];
}

interface UpdateStageMechanicalPrepFileRequest {
  description: string;
}

interface SaveDraftStageMechanicalPrepQuoteRequest {
  quoteApplicable: boolean;
  url: string;
  note: string;
}

type SubmitStageMechanicalPrepQuoteRequest =
  SaveDraftStageMechanicalPrepQuoteRequest;

interface ApproveStageMechanicalPrepRequest {
  note: string;
}

interface RejectStageMechanicalPrepRequest {
  note: string;
}

interface CompleteDraftStageMechanicalPrepRequest {
  motCarriedOut: boolean;
  serviceCarriedOut: boolean;
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

interface CompleteStageMechanicalPrepRequest {
  motCarriedOut: boolean;
  serviceCarriedOut: boolean;
  etaEcd: string;
  etaEcdNote: string;
  note: string;
}

export const RTK_STAGE_MECHANICAL_PREP_TAG = "StageMechanicalPrep" as const;
export const RTK_STAGE_MECHANICAL_PREP_MOT_TAG =
  "StageMechanicalPrepMot" as const;

const apiStageMechanicalPrep = RtkQueryService.enhanceEndpoints({
  addTagTypes: [
    RTK_STAGE_MECHANICAL_PREP_TAG,
    RTK_STAGE_MECHANICAL_PREP_MOT_TAG,
    RTK_VEHICLE_TAG,
    RTK_REPORT_TAG,
  ],
}).injectEndpoints({
  endpoints: (build) => ({
    getStageMechanicalPrepVehicles: build.query<
      GetStageMechanicalPrepVehiclesResponse,
      GetStageMechanicalPrepVehiclesRequest
    >({
      query: (params) => ({
        url: `/stage/mechanical-prep`,
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: RTK_STAGE_MECHANICAL_PREP_TAG,
                id: id,
              })),
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: "LIST" },
            ]
          : [{ type: RTK_STAGE_MECHANICAL_PREP_TAG, id: "LIST" }],
    }),
    findStageMechanicalPrepVehicle: build.query<
      FindStageMechanicalPrepVehicleResponse,
      string
    >({
      query: (vehicleId) => ({
        url: `/stage/mechanical-prep/${vehicleId}`,
        method: "GET",
      }),
      providesTags: (result, _, vehicleId) =>
        result ? [{ type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId }] : [],
    }),
    // ---
    findMotStageMechanicalPrepVehicle: build.query<FindMotResponse, string>({
      query: (vehicleId) => ({
        url: `/stage/mechanical-prep/${vehicleId}/mot`,
        method: "GET",
      }),
      providesTags: (result, _, vehicleId) =>
        result
          ? [{ type: RTK_STAGE_MECHANICAL_PREP_MOT_TAG, id: vehicleId }]
          : [],
    }),
    // ---
    assessStageMechanicalPrep: build.mutation<
      MessageResponse,
      { vehicleId: string; data: AssessStageMechanicalPrepRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/assess`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    // ---
    storeStageMechanicalPrepFiles: build.mutation<
      MessageResponse,
      { vehicleId: string; data: StoreStageMechanicalPrepFilesRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/files`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
            ]
          : [],
    }),
    updateStageMechanicalPrepFile: build.mutation<
      MessageResponse,
      {
        vehicleId: string;
        fileId: string;
        data: UpdateStageMechanicalPrepFileRequest;
      }
    >({
      query: ({ vehicleId, fileId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/files/${fileId}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
            ]
          : [],
    }),
    deleteStageMechanicalPrepFile: build.mutation<
      MessageResponse,
      { vehicleId: string; fileId: string }
    >({
      query: ({ vehicleId, fileId }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/files/${fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
            ]
          : [],
    }),
    // ---
    saveDraftStageMechanicalPrepQuote: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SaveDraftStageMechanicalPrepQuoteRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/quote/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
            ]
          : [],
    }),
    submitStageMechanicalPrepQuote: build.mutation<
      MessageResponse,
      { vehicleId: string; data: SubmitStageMechanicalPrepQuoteRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/quote`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    // ---
    approveStageMechanicalPrep: build.mutation<
      MessageResponse,
      { vehicleId: string; data: ApproveStageMechanicalPrepRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/approve`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    rejectStageMechanicalPrep: build.mutation<
      MessageResponse,
      { vehicleId: string; data: RejectStageMechanicalPrepRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/reject`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
    //
    completeDraftStageMechanicalPrep: build.mutation<
      MessageResponse,
      { vehicleId: string; data: CompleteDraftStageMechanicalPrepRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/completion/draft`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
            ]
          : [],
    }),
    completeStageMechanicalPrep: build.mutation<
      MessageResponse,
      { vehicleId: string; data: CompleteStageMechanicalPrepRequest }
    >({
      query: ({ vehicleId, data }) => ({
        url: `/stage/mechanical-prep/${vehicleId}/completion`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, _, { vehicleId }) =>
        result
          ? [
              { type: RTK_VEHICLE_TAG, id: vehicleId },
              { type: RTK_STAGE_MECHANICAL_PREP_TAG, id: vehicleId },
              { type: RTK_REPORT_TAG, id: "TODAY" },
            ]
          : [],
    }),
  }),
});

export const {
  useGetStageMechanicalPrepVehiclesQuery,
  useFindStageMechanicalPrepVehicleQuery,
  useLazyFindMotStageMechanicalPrepVehicleQuery,
  useAssessStageMechanicalPrepMutation,
  // Assesment files
  useStoreStageMechanicalPrepFilesMutation,
  useUpdateStageMechanicalPrepFileMutation,
  useDeleteStageMechanicalPrepFileMutation,
  // Assesment
  useSaveDraftStageMechanicalPrepQuoteMutation,
  useSubmitStageMechanicalPrepQuoteMutation,
  // Approval
  useApproveStageMechanicalPrepMutation,
  useRejectStageMechanicalPrepMutation,
  // Completion
  useCompleteDraftStageMechanicalPrepMutation,
  useCompleteStageMechanicalPrepMutation,
} = apiStageMechanicalPrep;

export default apiStageMechanicalPrep;
