import { Socket } from "socket.io-client";
import SocketIoContext from "./socketIo.context";
import { useEffect, useRef } from "react";
import { useAppSelector } from "@/store";
import { UserRole } from "@/types/role";
import socketIoService from "./socketIo";

const ALLOWED_ROLES = [UserRole.EMPLOYEE];

export function SocketIoProvider({ children }: React.PropsWithChildren) {
  const session = useAppSelector((state) => state.auth.session);
  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    if (session.activeRole && ALLOWED_ROLES.includes(session.activeRole)) {
      socket.current = socketIoService.initialize(session.accessToken!);

      return () => {
        socketIoService.disconnect();
      };
    }
  }, [session.activeRole, session.accessToken]);

  return (
    <SocketIoContext.Provider value={{ socket: socket.current }}>
      {children}
    </SocketIoContext.Provider>
  );
}
